import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoCompras.css';
import { BiSearchAlt2 } from "react-icons/bi";
import { FaCheck, FaCalendar } from "react-icons/fa";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { CgMoveRight } from 'react-icons/cg';
import { BsListUl } from "react-icons/bs";
import { Table } from 'reactstrap';
import { estiloBtnMasInformacion, coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalFormularioMoverComprasAlmacenAgroquimicos from '../../Complements/Modales/ModalFormularioCompras/ModalFormularioMoverComprasAlmacenAgroquimicos';
import ModalFormularioDetallesCompraNormal from '../../Complements/Modales/ModalFormularioCompras/ModalFormularioDetallesCompraNormal';

import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getCatalogoCompras, eliminarCompra } from '../../Services/Api';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillCalendarFill } from "react-icons/bs";
import { formatoNumero2 } from '../../Services/Validaciones';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import ModalCompraDirecta from '../../Complements/Modales/ModalCompraDirecta/ModalCompraDirecta';
import ModalCompraDirectaVer from '../../Complements/Modales/ModalCompraDirecta/ModalCompraDirectaVer';
import ModalCompraCombustible from '../../Complements/Modales/ModalCompraCombustible/ModalCompraCombustible';
import ModalEliminarCompra from '../../Complements/Modales/ModalEliminarCompra/ModalEliminarCompra';
import ModalFormularioDetallesServicios from '../../Complements/Modales/ModalFormularioCompras/ModalFormularioDetalleServicios';
registerLocale('es', es)


export default class CatalogoCompras extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: true,
      errordeacceso: false,

      txtfechacompraini: "",
      txtfechacomprafin: "",

      tabla: {
        encabezados: [

          "Proveedor",
          "Requisición",
          "Fecha compra",
          "Total",
          "Adeudo",
          "Estatus",
          "Detalles",
          "Almacén",
        ],
        dimensiones: [

          "25%",
          "40%",
          "12%",
          "10%",
          "10%",
          "10%",
          "5%",
          "250px"
        ],
        mindimensiones: [
          "50px",
          "100px",
          "100px",
          "146px",
          "120px",
          "120px",
          "120px",
          "100px",
          "250px"
        ],
        elementos: []
      },
      accionmodal: 1,
      titulomodal: '',
      redireccionandoainventario: false,
      redireccionandoaalertas: false,
      modalmoveraalmacen: false,
      itemseleccionado: null,

      listaalmacenes: [],
      //listaequipos: [],
      informacioncompra: [],

      modaldetallecompranormal: false,
      modalconfirmaraccion: false,
      itemeliminar: null,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,

      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      fechacompraini: new Date(),
      fechacomprafin: new Date(),

      modalcompradirecta: false,
      titulomodalcompradirecta: "",
      modalcompradirectaver: false,


      /*modal detalles servicios*/
      modaldetalleservicios: false,


      /*compra de combustible */
      modalcompracombustiblesver: false,
      idcompracombustible: 0,
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var tabla = this.state.tabla;
    tabla.elementos = [];

    var fechaactualFin = new Date();
    const fechaa = fechaactualFin.getDate();
    fechaactualFin.setDate(fechaa);
    const defaultValue = fechaactualFin.toLocaleDateString('en-CA');

    var fechaactualIni = new Date();
    fechaactualIni.setDate(fechaactualIni.getDate() - 7);
    const defaultValueFechaInicial = fechaactualIni.toLocaleDateString('en-CA');



    await this.setState({
      txtbuscador: '',
      txtfechacompraini: defaultValueFechaInicial,
      txtfechacomprafin: defaultValue,
      fechacompraini: fechaactualIni,
      tabla: tabla,
    })

    setTimeout(() => {
      this.consultarCatalogoCompra();
      this.dimensiones();
      this.cambiarContenido(1);
      window.addEventListener("resize", this.dimensiones);
      this.setState({ loadingspinners: false })
    }, 300);

  }

  consultarCatalogoCompra = async (llave, valor) => {
    var formatofechaini = "";
    var formatofechafin = "";
    var fechainiaux = 'txtfechacompraini' == llave ? valor : this.state.txtfechacompraini
    var fechafinaux = 'txtfechacomprafin' == llave ? valor : this.state.txtfechacomprafin

    if (fechainiaux.length > 0) {
      var [year, month, day] = fechainiaux.split("-");
      formatofechaini = `${day}/${month}/${year}`
    }

    if (fechafinaux.length > 0) {
      var [year, month, day] = fechafinaux.split("-");
      formatofechafin = `${day}/${month}/${year}`
    }

    var requestbody = {
      fechainicio: formatofechaini,
      fechafin: formatofechafin,
    }

    var fechasvalidas = await this.validarFechasDeCompra(formatofechaini, formatofechafin)
    if (fechasvalidas == 1) {
      this.setState({ loadingspinners: true })
      var catalogocompras = await getCatalogoCompras(requestbody);
      if (this.manejadorLlamadasApis(catalogocompras)) {
        this.setState({ loadingspinners: false })
        return;
      }

      var tabla = this.state.tabla;
      tabla.elementos = catalogocompras.data;

      await this.setState({
        txtbuscador: '',
        tabla: tabla
      })
      this.cambiarContenido(1);
      this.setState({ loadingspinners: false })
    }
  }

  validarFechasDeCompra = async (formatofechaini, formatofechafin) => {
    var fechainicio = new Date();
    var fechafin = new Date();
    var fechainiciovalido = false;
    var fechafinvalido = false;
    if (formatofechaini.length > 0) {
      fechainiciovalido = true;
      var fechacompraini = formatofechaini.split("/");
      fechainicio.setFullYear(fechacompraini[2], fechacompraini[1], fechacompraini[0]);
    }

    if (formatofechafin.length > 0) {
      fechafinvalido = true;
      var fechacomprafin = formatofechafin.split("/");
      fechafin.setFullYear(fechacomprafin[2], fechacomprafin[1], fechacomprafin[0]);
    }

    if (fechainiciovalido && fechafinvalido) {
      if (fechainicio <= fechafin) {
        return 1;
      } else {
        toast.info("Fecha inicio no puede ser mayor que fecha fin", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        var tabla = this.state.tabla;
        tabla.elementos = [];

        await this.setState({
          txtbuscador: '',
          tabla: tabla
        })
        this.cambairContenido(1);
        return 2;
      }
    }
    return 0;
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ loadingspinners: true, errordeacceso: false })
    this.componentDidMount();
  }

  saveInputLocacion = async (e) => {

    this.setState({ txtbuscador: e.target.value });

    if (e.target.value.trim().length > 2) {
      var data1 = JSON.parse(JSON.stringify(this.state.tabla))
      var data = data1.elementos;
      const filtros = data.filter((item) => item.requisicion.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) || item.proveedor.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

      if (filtros.length < 1) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.filtro(1, filtros)
    }
    else {
      this.setState({ filtrolistapaginada: [] });
    }

  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 2) {
      this.setState({ titulomodal: 'Mover compra a almacén', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
      this.setState({ modalmoveraalmacen: true });
    } else if (ban.opcion == 5) {
      this.setState({ titulomodal: 'Detalles de compra', accionmodal: 5, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
      this.setState({ modaldetallecompranormal: true });
    }

  }

  verModalCompraInformacion = async (ban) => {    
    if(parseInt(ban.contenido.sonservicios)==1){
      this.setState({ loadingspinners: true, modaldetalleservicios: true, informacioncompra: ban })
    }else{
      this.setState({ loadingspinners: true, modalmoveraalmacen: true, informacioncompra: ban });
    }    
  }

  cerrarModalMoverAAlmacen = (accion) => {
    if (accion == 5) {
      this.setState({ modaldetallecompranormal: !this.state.modaldetallecompranormal })
      this.consultarCatalogoCompra();
    } else {
      this.setState({ modalmoveraalmacen: !this.state.modalmoveraalmacen })
      if (accion == 1 || accion == 0) {
        this.consultarCatalogoCompra();
      }
    }
  }

  cerrarModalServicios = (accion) => {  
      this.setState({ modaldetalleservicios: !this.state.modaldetalleservicios })    
  }

  filtro = (pagenumber, array) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);
  }

  cambiarContenido = (pagenumber) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: [],
      listapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);
  }

  borrarBusqueda = () => {
    this.setState({ txtbuscador: '' })
    this.cambiarContenido(1);
    this.setState({ filtrolistapaginada: [] });
  }

  redireccioando = (itemalmacen) => {
    localStorage.setItem("datos", JSON.stringify(itemalmacen.contenido));
  }

  escribir = (prop) => async (event) => {
    var formatofecha = event.target.value;
    await this.setState({ [prop]: formatofecha });
    this.consultarCatalogoCompra();
  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  cambiarFecha = (item) => {
    this.setState({ [item.llave]: item.valor })
    let day = `0${item.valor.getDate()}`.slice(-2)
    let month = `0${item.valor.getMonth() + 1}`.slice(-2)
    let year = item.valor.getFullYear()
    this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    this.consultarCatalogoCompra(['txt' + item.llave], year + '-' + month + '-' + day)
  }


  /////////////// acciones para compras directas

  verModalCompraDirecta = (ban) => {
    if (ban.accion == 1) {
      //ASIGNAR PRODUCTO
      this.setState({ titulomodalcompradirecta: "Nueva compra directa", modalcompradirecta: true, opcioncompradirecta: ban.accion })
    }
    if (ban.accion == 2) {
      //ASIGNAR PRODUCTO
      this.setState({ titulomodalcompradirecta: "Editar compra directa", modalcompradirecta: true, opcioncompradirecta: ban.accion, compraseleccionada: ban.idcompra })
    }
    if (ban.accion == 3) {
      //ASIGNAR PRODUCTO
      this.setState({ titulomodalcompradirecta: "Ver compra directa", modalcompradirectaver: true, opcioncompradirecta: ban.accion, compraseleccionada: ban.idcompra })
    }
  }


  cerrarModalCompraDirecta = async (opciones) => {
    if (opciones != "0") {

      var aux = this.state.contenidorequisicion;
      var total = 0.00;
      opciones.productos.map((p) => {
        total = total + parseFloat(p.precio)
      })

      var newTotal = "" + total;
      var auxTotal = newTotal.split('.');
      if (auxTotal.length > 1) {
        newTotal = auxTotal[0].slice(0, 7) + "." + auxTotal[1].slice(0, 2)
      } else {
        newTotal = auxTotal[0].slice(0, 7) + ".00"
      }

      opciones.total = newTotal;

      if (opciones.btnEditado) {
        var indexcotizacioneditar = aux.findIndex((item => item.idcotizacion_proveedor == opciones.idcotizacion_proveedor))
        if (indexcotizacioneditar > -1) {
          aux[indexcotizacioneditar] = opciones;
        } else {
          toast.info("No se encontró la tarjeta a editar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
      } else {
        opciones.idcotizacion_proveedor = aux.length + 1;
        opciones.esdummy = 1;
        aux.push(opciones)
      }

      this.state.contenidorequisicion = aux;
      await this.setState({ estatuseditable: true })
      this.actualizarResumenPrincipal();
      this.actualizarResumenGeneral();
    }

    this.setState({ modalcompradirecta: false, modalcompradirectaver: false, modalcompracombustiblesver: false })
    this.actualizarTabla();
  }

  actualizarContenido = (tarjetaCotizacion) => {
    this.setState({ accionmodal: 4, modalcompradirecta: true, itemseleccionado: tarjetaCotizacion });
  }

  verModalCompraCombustible = (ban) => {
    this.setState({ modalcompracombustiblesver: true, tipocompracombustible: ban.accion, idcompracombustible: ban.id })
  }

  eliminarCompra = (ban) => {
    this.setState({ modalconfirmaraccion: true, itemeliminar: ban.idcompra })
  }

  eliminarElemento = async (elemento) => {
    var resp = undefined;
    this.setState({ modalconfirmaraccion: !this.state.modalconfirmaraccion })
    if (elemento == 1) {
      var resp = await eliminarCompra({ "idcompra": this.state.itemeliminar.idcompra });
      if (this.manejadorLlamadasApis(resp)) {
        this.setState({ loadingspinners: false })
        return;
      }
      toast.success("Compra eliminada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }


  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesAlmacen bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.redireccionandoainventario) ? (<AccionesAlmacen bandera={'verinventario'} />) : null}
        {(this.state.redireccionandoaalertas) ? (<AccionesAlmacen bandera={'veralertas'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}


        {(this.state.modalcompradirecta == true) ? (
          <ModalCompraDirecta
            tipoedicion={this.state.opcioncompradirecta}
            idcomprabuscar={this.state.compraseleccionada}
            titulomodalcompradirecta={this.state.titulomodalcompradirecta}
            cerrarModal={this.cerrarModalCompraDirecta}
            show={this.state.modalcompradirecta}
          />
        ) : null}

        {(this.state.modalcompradirectaver == true) ? (
          <ModalCompraDirectaVer
            tipoedicion={this.state.opcioncompradirecta}
            idcomprabuscar={this.state.compraseleccionada}
            titulomodalcompradirecta={this.state.titulomodalcompradirecta}
            cerrarModal={this.cerrarModalCompraDirecta}
            show={this.state.modalcompradirectaver}
          />
        ) : null}


        {(this.state.modalcompracombustiblesver == true) ? (
          <ModalCompraCombustible
            tipoedicion={this.state.tipocompracombustible}
            idcompra={this.state.idcompracombustible}
            idcomprabuscar={this.state.compraseleccionada}
            titulomodal={"Nueva compra combustible"}
            cerrarModal={this.cerrarModalCompraDirecta}
            show={this.state.modalcompracombustiblesver}
          />
        ) : null}


        {(this.state.modalmoveraalmacen == true) ? (
          <ModalFormularioMoverComprasAlmacenAgroquimicos
            cerrarModal={this.cerrarModalMoverAAlmacen}
            show={this.state.modalmoveraalmacen}
            item={this.state.informacioncompra}
            activarSpinner={this.activarSpinner}
            verModalCompraInformacion={this.verModalCompraInformacion}
          />
        ) : null}

        {(this.state.modaldetalleservicios == true) ? (
          <ModalFormularioDetallesServicios
            cerrarModal={this.cerrarModalServicios}
            show={this.state.modaldetalleservicios}
            item={this.state.informacioncompra}
            activarSpinner={this.activarSpinner}
            verModalCompraInformacion={this.verModalCompraInformacion}
          />
        ) : null}

        {(this.state.modaldetallecompranormal == true) ? (
          <ModalFormularioDetallesCompraNormal
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalMoverAAlmacen}
            show={this.state.modaldetallecompranormal}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            listaalmacenes={this.state.listaalmacenes}
            activarSpinner={this.activarSpinner}
            listacompras={this.state.tabla.elementos}
          />
        ) : null}



        {(this.state.modalconfirmaraccion == true) ? (
          <ModalEliminarCompra
            titulomodal={"Eliminar compra"}
            cerrarModalConfirmar={this.eliminarElemento}
            show={this.state.modalconfirmaraccion}
            cuerpo={"¿Seguro que desea eliminar esta compra?"}
            item={this.state.itemeliminar}
          />
        ) : null}


        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Compras</label>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}>

                  <div className='subcontenedorIconoCalendarioCompras'>
                    <BsFillCalendarFill className='iconoCalendario' style={{ color: this.state.colortipolbl, stroke: this.state.colortipolbl }} />
                  </div>
                  <div className='row'>
                    <div className=' col-sm-4 col-md-4'>
                      <label className='lblTexto letraTitulos labelFecha' style={{ color: this.state.colortipolbl, }}>Fecha compra de:</label>
                    </div>
                    <div className='row col-sm-8 col-md-8' style={{ marginLeft: '10px' }}>
                      <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                        <DatePicker
                          selected={this.state.fechacompraini}
                          onChange={(date) => this.cambiarFecha({ llave: "fechacompraini", valor: date })}
                          dateFormat="dd/MM/yyyy"
                          locale="es"
                          customInput={
                            <DateButton />
                          }
                        />
                      </div>
                      <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>a:</label>
                      </div>

                      <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                        <DatePicker
                          selected={this.state.fechacomprafin}
                          onChange={(date) => this.cambiarFecha({ llave: "fechacomprafin", valor: date })}
                          dateFormat="dd/MM/yyyy"
                          locale="es"
                          customInput={
                            <DateButton />
                          }
                        />
                      </div>
                    </div>
                  </div>


                </div>

              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar requisición/proveedor"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalCompraDirecta.bind(this, { id: 1, accion: 1 })}
                >+ Nueva compra </button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalCompraCombustible.bind(this, { id: 1, accion: 1 })}
                >+ Nueva compra combustible</button>
              </div>

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}
        <br />
        <div className="container-fluid containerTabla anchoMaximo">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla, textAlign: index > 3 ? 'center' : '' }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 ?

                    this.state.listapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        {/* <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.idcompra}
                        </td>*/}
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.proveedor}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], wordBreak: "break-word" }}>
                          {(item.soncombustibles == 1) ? ("Combustibles") : (item.requisicion)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                          {item.fechacompra}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }} >
                          {item.descuento != "0.0000" ? <label><span> ${formatoNumero2(item.total)} c/dto</span><del style={{ color: 'red' }}> ${formatoNumero2(item.precio)}</del></label> : <label>${formatoNumero2(item.precio)}</label>}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} >
                          <label>

                            {isNaN(parseFloat(item.adeudo)) ? (item.adeudo) : ("$" + formatoNumero2(item.adeudo))}

                          </label>
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ textAlign: 'center', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >
                          <label>{item.estatus != undefined ? item.estatus : "Desconocido"}</label>
                        </td>

                        <td className="letraNormal" style={{
                          verticalAlign: "middle",
                          minWidth: this.state.tabla.mindimensiones[7],
                          width: this.state.tabla.dimensiones[7]
                        }} >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "5", contenido: item })}
                            style={{
                              backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar,
                              border: this.state.btnbordereditar
                            }}><BsListUl className="icoBotones" /></button>
                        </td>





                        <td className="letraNormal " style={{
                          verticalAlign: "middle",
                          minWidth: this.state.tabla.mindimensiones[8], width: this.state.tabla.dimensiones[8]
                        }}>

                          <div style={{ display: "flex" }}>

                            {(item.identificadorestatus == 1 || item.identificadorestatus == 2 || item.identificadorestatus == 3) ? (
                              // si la compra es de estatus en proceso, parcial o completada
                              (item.soncombustibles == 1) ? (
                                <button className='btnEditar'
                                  onClick={this.verModalCompraCombustible.bind(this, { id: item.idcompra, accion: 2 })}
                                  style={{
                                    backgroundColor: "white", color: this.state.btncoloreditar, border: "0px",
                                    borderBottom: "2px solid" + this.state.btncoloreditar, width: "90%"
                                  }}>
                                  <FaCheck className="icoBotones" style={{ color: this.state.btncoloreditar }} />Combustibles
                                </button>

                              ) : (
                                (item.enalmacen == 1) ? (
                                  <button className='btnEditar' onClick={this.verModalCompraInformacion.bind(this, { contenido: item })}
                                    style={{
                                      backgroundColor: "white", color: this.state.btncoloreditar, border: "0px",
                                      borderBottom: "2px solid" + this.state.btncoloreditar, width: "90%"
                                    }}>
                                    <FaCheck className="icoBotones" style={{ color: this.state.btncoloreditar }} /> En almacén
                                  </button>
                                ) : (
                                  <>
                                    <button className='btnEditar' onClick={this.verModalCompraInformacion.bind(this, { contenido: item })}
                                      style={{
                                        backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar,
                                        border: this.state.btnbordereditar,
                                        width: (item.sepuedeeliminar == true) ? ("calc(90% - 150px)") : "90%",
                                        minWidth: "170px"
                                      }} >
                                      <CgMoveRight className="icoBotones" /> Mover a almacén</button>

                                    {(item.sepuedeeliminar == true) ? (
                                      <button className='btnEditar'
                                        style={{
                                          color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo,
                                          border: this.state.btnbordereditar, width: "140px"
                                        }}
                                        onClick={this.eliminarCompra.bind(this, { idcompra: item })} >
                                        Eliminar compra
                                      </button>
                                    ) : null}
                                  </>

                                )
                              )

                            ) : (// si la compra es compra directa en proceso
                              <>
                                <label style={{ display: "flex" }}>
                                  <button className='btnEditar'
                                    style={{
                                      color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo,
                                      border: this.state.btnbordereditar, width: "140px"
                                    }}
                                    onClick={this.verModalCompraDirecta.bind(this, { id: 1, accion: 2, idcompra: item.idcompra })} >
                                    Editar compra
                                  </button>

                                  <button className='btnEditar'
                                    style={{
                                      color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo,
                                      border: this.state.btnbordereditar, width: "50px"
                                    }}
                                    onClick={this.verModalCompraDirecta.bind(this, { id: 1, accion: 3, idcompra: item.idcompra })} >
                                    Ver
                                  </button>
                                  {(item.sepuedeeliminar == true) ? (
                                    <button className='btnEditar'
                                      style={{
                                        color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo,
                                        border: this.state.btnbordereditar, width: "140px"
                                      }}
                                      onClick={this.eliminarCompra.bind(this, { idcompra: item })} >
                                      Eliminar compra
                                    </button>
                                  ) : null}
                                </label>
                              </>
                            )}


                          </div>





                        </td>

                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.proveedor}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                          {(item.soncombustibles == 1) ? ("Combustibles") : (item.requisicion)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                          {item.fechacompra}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }} >
                          {item.descuento != "0.0000" ? <label><span> ${formatoNumero2(item.total)} c/dto</span><del style={{ color: 'red' }}> ${formatoNumero2(item.precio)}</del></label> : <label>${formatoNumero2(item.precio)}</label>}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} >
                          <label>
                            {isNaN(parseFloat(item.adeudo)) ? (item.adeudo) : ("$" + formatoNumero2(item.adeudo))}
                          </label>
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ textAlign: 'center', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >
                          <label>{item.estatus != undefined ? item.estatus : "Desconocido"}</label>
                        </td>

                        <td className="letraNormal" style={{ minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7] }} >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "5", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BsListUl className="icoBotones" /></button>
                        </td>

                        <td className="letraNormal " style={{ minWidth: this.state.tabla.mindimensiones[8], width: this.state.tabla.dimensiones[8] }}>

                          <div style={{ display: "flex" }}>

                            {(item.identificadorestatus == 1 || item.identificadorestatus == 2 || item.identificadorestatus == 3) ? (
                              // si la compra es de estatus en proceso, parcial o completada
                              (item.soncombustibles == 1) ? (
                                <button className='btnEditar'
                                  onClick={this.verModalCompraCombustible.bind(this, { id: item.idcompra, accion: 2 })}
                                  style={{
                                    backgroundColor: "white", color: this.state.btncoloreditar, border: "0px",
                                    borderBottom: "2px solid" + this.state.btncoloreditar, width: "90%"
                                  }}>
                                  <FaCheck className="icoBotones" style={{ color: this.state.btncoloreditar }} />Combustibles
                                </button>

                              ) : (
                                (item.enalmacen == 1) ? (
                                  <button className='btnEditar' onClick={this.verModalCompraInformacion.bind(this, { contenido: item })}
                                    style={{
                                      backgroundColor: "white", color: this.state.btncoloreditar, border: "0px",
                                      borderBottom: "2px solid" + this.state.btncoloreditar, width: "90%"
                                    }}>
                                    <FaCheck className="icoBotones" style={{ color: this.state.btncoloreditar }} /> En almacén
                                  </button>
                                ) : (
                                  <>
                                    <button className='btnEditar' onClick={this.verModalCompraInformacion.bind(this, { contenido: item })}
                                      style={{
                                        backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar,
                                        border: this.state.btnbordereditar,
                                        width: (item.sepuedeeliminar == true) ? ("calc(90% - 150px)") : "90%",
                                        minWidth: "170px"
                                      }} >
                                      <CgMoveRight className="icoBotones" /> Mover a almacén</button>

                                    {(item.sepuedeeliminar == true) ? (
                                      <button className='btnEditar'
                                        style={{
                                          color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo,
                                          border: this.state.btnbordereditar, width: "140px"
                                        }}
                                        onClick={this.eliminarCompra.bind(this, { idcompra: item })} >
                                        Eliminar compra
                                      </button>
                                    ) : null}
                                  </>

                                )
                              )

                            ) : (// si la compra es compra directa en proceso
                              <>
                                <label style={{ display: "flex" }}>
                                  <button className='btnEditar'
                                    style={{
                                      color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo,
                                      border: this.state.btnbordereditar, width: "140px"
                                    }}
                                    onClick={this.verModalCompraDirecta.bind(this, { id: 1, accion: 2, idcompra: item.idcompra })} >
                                    Editar compra
                                  </button>

                                  <button className='btnEditar'
                                    style={{
                                      color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo,
                                      border: this.state.btnbordereditar, width: "50px"
                                    }}
                                    onClick={this.verModalCompraDirecta.bind(this, { id: 1, accion: 3, idcompra: item.idcompra })} >
                                    Ver
                                  </button>
                                  {(item.sepuedeeliminar == true) ? (
                                    <button className='btnEditar'
                                      style={{
                                        color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo,
                                        border: this.state.btnbordereditar, width: "140px"
                                      }}
                                      onClick={this.eliminarCompra.bind(this, { idcompra: item })} >
                                      Eliminar compra
                                    </button>
                                  ) : null}
                                </label>
                              </>
                            )}


                          </div>






                        </td>


                      </tr>

                    ))
                  }
                </tbody>
              </Table>
            </div>

          </div>
        </div>

        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
  </button>
));

function AccionesAlmacen({ bandera }) {
  const history = useHistory();
  const location = useLocation();
  history.push(location.pathname);
  if (bandera == 'verinventario') {
    return (<Redirect from='/' to={"/catalogoinventarioalmacen"} />)
  } else if (bandera == "veralertas") {
    return (<Redirect from='/' to={"/catalogoalertasalmacen"} />)
  }

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}