import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarSoloLetrasYEspacios, validarNumeros,validarNumerosDecimales2,validarLongitud } from '../../../Services/Validaciones';
import { getListaBitacoraTraslados, postCatalogoTrasladoRecepcionGuardar } from '../../../Services/Api'
import 'react-toastify/dist/ReactToastify.css';
import { FaCalendar } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputHora from '../../InputHora/InputHora'
import { ToastContainer, toast } from 'react-toastify';

export default class ModalFormularioBitacoraRecepcionFruta extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,

            idcatalogo_bitacora_traslados_recepcion:(props.accion == 2) ? (props.item.idcatalogo_bitacora_traslados_recepcion) : 0,

            fecha: (props.accion == 2) ? (this.convertirFechaCompleta(props.item.fecha)) : '',
            txtfecha: (props.accion == 2) ? (props.item.fecha) : '',
            vermsgerrorfecha: false,
            msgerrorfecha: "",

            txtzona: (props.accion == 2) ? (props.item.idcatalogo_zona) : '0',
            vermsgerrorzonas: false,
            msgerrorzona: "",

            txtrancho: (props.accion == 2) ? (props.item.idcatalogo_rancho) : '0',
            vermsgerrorranchos: false,
            msgerrorrancho: "",

            txthora:(props.accion != 1) ? (props.item.hora) : '',
            vermsgerrorhora: false,
            msgerrorhora: "",

            txtfolio: (props.accion == 2) ? (props.item.idcatalogo_bitacora_traslados_detalles) : 0,
            vermsgerrorfolio: false,
            msgerrorfolio: "",

            txtnombre: (props.accion == 2) ? (props.item.personarecibe) : '',
            vermsgerrornombre: false,
            msgerrornombre: "",

            txtnumrejas: (props.accion == 2) ? (props.item.rejas) : '',
            vermsgerrornumrejas: false,
            msgerrornumrejas: "",

            txtticket: (props.accion == 2) ? (props.item.ticket) : '',
            vermsgerrorticket: false,
            msgerrorticket: "",

            txtpesoinicial: (props.accion == 2) ? (props.item.pesoinicial) : '',
            vermsgerrorpesoinicial: false,
            msgerrorpesoinicial: "",

            txtpesofinal: (props.accion == 2) ? (props.item.pesofinal) : '',
            vermsgerrorpesofinal: false,
            msgerrorpesofinal: "",

            txtpesoneto: (props.accion == 2) ? (props.item.pesoneto) : '',
            vermsgerrorpesoneto: false,
            msgerrorpesoneto: "",

            arrayestatus: props.estatus,
            idestatus: (props.accion == 2) ? (props.item.idestatus) : 1,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            listazonas: props.listazonas,
            listaranchos: props.listaranchos,
            listatraslados:[]
        }
    }
    async componentDidMount(){
        if(this.props.accion == 2){
            var respbitacoratraslados = await getListaBitacoraTraslados({
                "fecha": this.props.item.fecha
            });
            if (this.manejadorLlamadasApis(respbitacoratraslados)) {
            return;
            }
            this.setState({listatraslados:respbitacoratraslados.data})
        }
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    cambiarFecha = async (item) => {
        this.setState({ [item.llave]: item.valor })

        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

        var respbitacoratraslados = await getListaBitacoraTraslados({
            "fecha": year + '-' + month + '-' + day
        });
        if (this.manejadorLlamadasApis(respbitacoratraslados)) {
        return;
        }
        this.setState({listatraslados:respbitacoratraslados.data})
    }

    convertirFechaCompleta = (fecha) => {
        const fechagen = new Date();
        var [year, month, day] = fecha.split("-");
        var fechaseteada=fechagen.setFullYear(year, month -1 , day);

        return fechaseteada;
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
        if(prop == "txtpesoinicial"){
            var peso_inicial =  event.target.value;
            var peso_final = this.state.txtpesofinal;
            var peso_neto = peso_final - peso_inicial;
            this.setState({txtpesoneto:peso_neto.toFixed(2)})
        }
        else if(prop == "txtpesofinal"){
            var peso_inicial =  this.state.txtpesoinicial;
            var peso_final = event.target.value;
            var peso_neto = peso_final - peso_inicial;
            this.setState({txtpesoneto:peso_neto.toFixed(2)})
        }
    }

    escribirHora = (hora) =>{
        this.setState({ txthora: hora});
    }

    format_numero_decimales = (number) => {
        return new Intl.NumberFormat('en-US',{ minimumFractionDigits: 2 }).format(number);
    }

    guardarCambios = async () => {
        this.setState({ vermsgerrorfecha: false })
        this.setState({ vermsgerrorhora: false })
        this.setState({ vermsgerrorfolio: false })
        this.setState({ vermsgerrornombre: false })
        this.setState({ vermsgerrornumrejas: false })
        this.setState({ vermsgerrorticket: false })
        this.setState({ vermsgerrorpesoinicial: false })
        this.setState({ vermsgerrorpesofinal: false })
        this.setState({ vermsgerrorpesoneto: false })

        let validacionescorrectas = true
        let longitudvalida = ""
        let textvalid = ""

        if(this.state.txtfecha=="" || this.state.txtfecha==null){
            this.setState({ msgerrorfecha: "Selecciona una fecha" })
            this.setState({ vermsgerrorfecha: true })
            validacionescorrectas = false;
        }

        if (this.state.txthora == "") {
            this.setState({ msgerrorhora: "Selecione la hora de entrada" })
            this.setState({ vermsgerrorhora: true })
            validacionescorrectas = false;
        }

        if (this.state.txtfolio < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorfolio: "Seleccione un folio" })
            this.setState({ vermsgerrorfolio: true })
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 100};
        textvalid = validarSoloLetrasYEspacios(this.state.txtnombre.trim(), longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrornombre: textvalid.mensaje })
            this.setState({ vermsgerrornombre: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 4};
        textvalid = validarNumeros(this.state.txtnumrejas, longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrornumrejas: textvalid.mensaje })
            this.setState({ vermsgerrornumrejas: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 100};
        textvalid = validarLongitud(this.state.txtticket.trim(), longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrorticket: textvalid.mensaje })
            this.setState({ vermsgerrorticket: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 1, longitudmaxima:9};
        textvalid = validarNumerosDecimales2(this.state.txtpesoinicial, longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrorpesoinicial: textvalid.mensaje })
            this.setState({ vermsgerrorpesoinicial: true })
            validacionescorrectas = false;
        }

        textvalid = validarNumerosDecimales2(this.state.txtpesofinal, longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrorpesofinal: textvalid.mensaje })
            this.setState({ vermsgerrorpesofinal: true })
            validacionescorrectas = false;
        }

        if((this.state.txtpesofinal - this.state.txtpesoinicial) < 0 || (this.state.txtpesofinal - this.state.txtpesoinicial) == 0){
            this.setState({ msgerrorpesoneto: "Peso neto no puede ser negativo ni 0" })
            this.setState({ vermsgerrorpesoneto: true })
            this.setState({ msgerrorpesoinicial: "Peso inicial no puede ser mayor o igual a peso final" })
            this.setState({ vermsgerrorpesoinicial: true })
            validacionescorrectas = false;
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);
            var resp = undefined;

            if (this.state.accion == 1) {
                let json = {
                    "idcatalogo_bitacora_traslados_recepcion": 0,
                    "idcatalogo_bitacora_traslados_detalles":  this.state.txtfolio,
                    "fecha": this.state.txtfecha,
                    "hora": this.state.txthora,
                    "personarecibe": this.state.txtnombre,
                    "rejas": this.state.txtnumrejas,
                    "ticket": this.state.txtticket,
                    "pesoinicial": this.state.txtpesoinicial,
                    "pesofinal": this.state.txtpesofinal
                }

                resp = await postCatalogoTrasladoRecepcionGuardar(json);
            } else if (this.state.accion == 2) {
                let json = {
                    "idcatalogo_bitacora_traslados_recepcion": this.state.idcatalogo_bitacora_traslados_recepcion,
                    "idcatalogo_bitacora_traslados_detalles":  this.state.txtfolio,
                    "fecha": this.state.txtfecha,
                    "hora": this.state.txthora,
                    "personarecibe": this.state.txtnombre,
                    "rejas": this.state.txtnumrejas,
                    "ticket": this.state.txtticket,
                    "pesoinicial": this.state.txtpesoinicial,
                    "pesofinal": this.state.txtpesofinal
                }
                resp = await postCatalogoTrasladoRecepcionGuardar(json);
            }

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
            else if (resp == -1) {
                this.setLoadingSpinner(false);
                toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }

    }

    render() {
        return (
            <>
                {/*///////// Sección mensajes toast ////////*/}
                <ToastContainer />

                <Modal
                    isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
                        <br />
                        <br />
    
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/*///////// Sección de hora recepción ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Hora recepción</p>
                                <InputHora
                                    hora={this.state.txthora}
                                    escribir={this.escribirHora}
                                    accion={this.props.accion}
                                />
                                {this.state.vermsgerrorhora ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorhora}</p>
                                :null}

                                {/*///////// Sección de fecha ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha</p>
                                <div className='col-auto' style={{ margin: '0px', paddings: '0px', zIndex: '3' }}>
                                    <DatePicker
                                        selected={this.state.fecha}
                                        onChange={(date) => this.cambiarFecha({ llave: "fecha", valor: date })}
                                        //dateFormat="dd/MM/yyyy"
                                        dateFormat="yyyy-MM-dd"
                                        locale="es"
                                        customInput={
                                            <DateButton />
                                        }
                                    />
                                </div>
                                {this.state.vermsgerrorfecha ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfecha}</p>
                                :null}

                                {/*///////// Sección de folio traslado ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Folio de traslado </p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtfolio")}>
                                                {this.state.listatraslados == undefined || this.state.listatraslados.length == 0 ?
                                                    <>
                                                        <option disabled="disabled" selected={true}>Seleccionar</option>
                                                        <option disabled="disabled">No hay folios</option>
                                                    </>
                                                :
                                                    <>
                                                        <option disabled="disabled" selected={this.state.txtfolio==0 ? true : false}>Seleccionar</option>
                                                        {this.state.listatraslados.map((i) => (
                                                                <option
                                                                    value={i.idcatalogo_bitacora_traslados_detalles}
                                                                    key={"tipo_" + i.idcatalogo_bitacora_traslados_detalles}
                                                                    selected={i.idcatalogo_bitacora_traslados_detalles==this.state.txtfolio ? true : false}
                                                                >
                                                                    {i.folio}
                                                                </option>
                                                        ))}
                                                    </>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {this.state.vermsgerrorfolio ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfolio}</p>
                                :null}

                                {/*///////// Sección de recibe ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Recibe</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'}
                                    value={this.state.txtnombre}
                                    onChange={this.escribir("txtnombre")}
                                >
                                </input>
                                {this.state.vermsgerrornombre ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrornombre}</p>
                                :null}

                               {/*///////// Sección de cantidad de rejas ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Cantidad de rejas</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'number'}
                                    value={this.state.txtnumrejas}
                                    onChange={this.escribir("txtnumrejas")}
                                >
                                </input>
                                {this.state.vermsgerrornumrejas ?
                                    <p className="msgError letraNormal"  style={{ color: this.state.colormsgerror }}>{this.state.msgerrornumrejas}</p>
                                :null}

                                {/*///////// Sección de ticket ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Ticket</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'}
                                    value={this.state.txtticket}
                                    onChange={this.escribir("txtticket")}
                                >
                                </input>
                                {this.state.vermsgerrorticket ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}> {this.state.msgerrorticket} </p>
                                :null}

                                {/*///////// Sección de peso inicial ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Peso inicial</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'number'}
                                    value={this.state.txtpesoinicial}
                                    onChange={this.escribir("txtpesoinicial")}
                                >
                                </input>
                                {this.state.vermsgerrorpesoinicial ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}> {this.state.msgerrorpesoinicial}</p>
                                :null}

                                {/*///////// Sección de peso final ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Peso final</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'number'}
                                    value={this.state.txtpesofinal}
                                    onChange={this.escribir("txtpesofinal")}
                                >
                                </input>
                                {this.state.vermsgerrorpesofinal ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorpesofinal}</p>
                                :null}

                                {/*///////// Sección de peso  neto ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Peso neto</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'number'}
                                    value={this.state.txtpesoneto}
                                    onChange={this.escribir("txtpesoneto")}
                                    readOnly={true}
                                >
                                </input>
                                {this.state.vermsgerrorpesoneto ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorpesoneto}</p>
                                :null}

                            </div>
                        </div>

                        {/*///////// Sección de botones ////////*/}
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

{/*///////// Sección de botón de calendario ////////*/}
const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{
            backgroundColor: 'transparent', padding: '0px 0px 0px 0px', borderRadius: '0px',
            borderBottom: '2px solid #868686',width: '96%',margin: '0px 8px 18px 8px'
        }}
    >
        <label style={{ width: '50%', textAlign: 'left', cursor: 'pointer' }}>{value ? value: 'dd/mm/aaaa'}</label>
        <FaCalendar className="icoBotones" style={{ minWidth: '28px' }}/>
    </button>
));


