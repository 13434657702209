import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/login/Login';

import CatalogoClientes from './pages/CatalogoClientes/CatalogoClientes';
import CatalogoProveedores from './pages/CatalogoProveedores/CatalogoProveedores';
import Cliente from './pages/Cliente/Cliente';
import CatalogoPlagas from './pages/CatalogoPlagas/CatalogoPlagas';
import CatalogoPresentacionProducto from './pages/CatalogoPresentacionProductos/CatalogoPresentacionProducto';
import CatalogoUnidadesDeMedida from './pages/CatalogoUnidadesDeMedida/CatalogoUnidadesDeMedida';
import CatalogoAreasTrabajo from './pages/CatalogoAreasTrabajo/CatalogoAreasTrabajo';
import CatalogoDocumentos from './pages/CatalogoDocumentos/CatalogoDocumentos';

import CatalogoTipoClientes from './pages/CatalogoTipoClientes/CatalogoTipoClientes';
import CatalogoTipoDocumentos from './pages/CatalogoTipoDocumentos/CatalogoTipoDocumentos';
import CatalogoTipoProveedores from './pages/CatalogoTipoProveedores/CatalogoTipoProveedores';
import CatalogoTipoUsuarios from './pages/CatalogoTipoUsuarios/CatalogoTipoUsuarios';
import CatalogoTipoProductos from './pages/CatalogoTipoProductos/CatalogoTipoProductos';
import CatalogoTipoPlagas from './pages/CatalogoTipoPlagas/CatalogoTipoPlagas';
import CatalogoTipoCultivos from './pages/CatalogoTipoCultivos/CatalogoTipoCultivos';
import CatalogoCultivos from './pages/CatalogoCultivos/CatalogoCultivos';
import CatalogoIngredientesActivos from './pages/CatalogoIngredientesActivos/CatalogoIngredientesActivos';
import CatalogoMovimientosAlmacen from './pages/CatalogoMovimientosAlmacen/CatalogoMovimientosAlmacen';
import CatalogoPuestosTrabajo from './pages/CatalogoPuestosTrabajo/CatalogoPuestosTrabajo';
import CatalogoProductos from './pages/CatalogoProductos/CatalogoProductos';
import CatalogoAlertasAlmacen from './pages/CatalogoAlertasAlmacen/CatalogoAlertasAlmacen';
import PerfilUsuario from './pages/PerfilUsuario/PerfilUsuario';

import CatalogoAlmacenes from './pages/CatalogoAlmacenes/CatalogoAlmacenes';
import CatalogoTipoSuelo from './pages/CatalogoTipoSuelo/CatalogoTipoSuelo';
import CatalogoLotesCultivo from './pages/CatalogoLotesCultivo/CatalogoLotesCultivo';

import CatalogoPatronesInjerto from './pages/CatalogoPatronesInjerto/CatalogoPatronesInjerto';
import CatalogoRanchosCultivo from './pages/CatalogoRanchosCultivo/CatalogoRanchosCultivo'


import CatalogoProducto from './pages/CatalogoProducto/CatalogoProducto';
import CatalogoFamiliaDocumentos from './pages/CatalogoFamiliaDocumentos/CatalogoFamiliaDocumentos';
import CatalogoFamiliaProductos from './pages/CatalogoFamiliaProductos/CatalogoFamiliaProductos';

import VentanaProveedor from './pages/VentanaProveedor/VentanaProveedor';

import CatalogoSolicitudMInventario from './pages/CatalogoSolicitudMInventario/CatalogoSolicitudMInventario';
import CatalogoEstatusSiembra from './pages/CatalogoEstatusSiembra/CatalogoEstatusSiembra';
import CatalogoZonaCultivo from './pages/CatalogoZonaCultivo/CatalogoZonaCultivo';
import CatalogoTablaCultivo from './pages/CatalogoTablaCultivo/CatalogoTablaCultivo';

import CatalogoInventarioAlmacen from './pages/CatalogoInventarioAlmacen/CatalogoInventarioAlmacen';

import CatalogoUsuarios from './pages/CatalogoUsuarios/CatalogoUsuarios';
import DetallesUsuario from './pages/CatalogoUsuarios/DetallesUsuario';

//import EditarRequisicion from './pages/EditarRequisicion/EditarRequisicion';

import CatalogoTipoRequesicion from './pages/CatalogoTipoRequesicion/CatalogoTipoRequesicion';
import CatalogoNuevaRequisicion from './pages/CatalogoNuevaRequisicion/CatalogoNuevaRequisicion';

import CatalogoTipoActividades from './pages/CatalogoTipoActividades/CatalogoTipoActividades';
import CatalogoActividades from './pages/CatalogoActividades/CatalogoActividades';
import CatalogoCompras from './pages/CatalogoCompras/CatalogoCompras';

import CatalogoRequisiciones from './pages/CatalogoRequisiciones/CatalogoRequisiciones';
import Requisicion from './pages/Requisicion/Requisicion';
import CatalogoTipoMaquinaria from './pages/CatalogoTipoMaquinaria/CatalogoTipoMaquinaria';
import CatalogoCombustible from './pages/CatalogoCombustible/CatalogoCombustible';
import CatalogoMaquinarias from './pages/CatalogoMaquinarias/CatalogoMaquinarias';

import CatalogoTipoEquipos from './pages/CatalogoTipoEquipos/CatalogoTipoEquipos';
import CatalogoEquipos from './pages/CatalogoEquipos/CatalogoEquipos'
import CatalogoNivelesAutorizacion from './pages/CatalogoNivelesAutorizacion/CatalogoNivelesAutorizacion';
import Cotizacion from './pages/Cotizaciones/Cotizacion';
import RectificarProductos from './pages/RectificarProductos/RectificarProductos';

import CatalogoAlmacenesCombustible from './pages/CatalogoAlmacenesCombustible/CatalogoAlmacenesCombustible'
import AlmacenCombustible from './pages/AlmacenCombustible/AlmacenCombustible'

import AutorizarCotizaciones from './pages/AutorizarCotizaciones/AutorizarCotizaciones'
import Demonio from './pages/Demonio/Demonio'

import CatalogoPlanAnual from './pages/CatalogoPlanAnual/CatalogoPlanAnual'

import PropuestaAnual from './pages/CatalogoPropuestaAnual/PropuestaAnual';

import OrdenTrabajo from './pages/OrdenTrabajo/OrdenTrabajo'
import ReporteOrdenTrabajo from './pages/ReporteOrdenTrabajo/ReporteOrdenTrabajo'

import CatalogoOrdenesTrabajo from './pages/CatalogoOrdenesTrabajo/CatalogoOrdenesTrabajo';

import CatalogoProductosGenerales from './pages/CatalogoProductosGenerales/CatalogoProductosGenerales';

import Bienvenido from './pages/Bienvenido/Bienvenido'
import CatalogoAlmacenProductosGenerales from './pages/AlmacenProductosGenerales/AlmacenProductosGenerales';
import CatalogoAlmacenEquiposHerramientas from './pages/AlmacenEquiposHerramientas/AlmacenEquiposHerramientas';


import CatalogoTipoRequesicionGeneral from './pages/CatalogoTipoRequesicionGeneral/CatalogoTipoRequesicionGeneral';

import CatalogoTipoProductosGeneral from './pages/CatalogoTipoProductosGeneral/CatalogoTipoProducosGeneral';

import CatalogoRequisicionesGenerales from './pages/CatalogoRequisicionesGenerales/CatalogoRequisicionesGenerales';
import RequisicionGeneral from './pages/RequisicionGeneral/RequisicionGeneral'

import CotizacionProductoGeneral from './pages/CotizacionesProductosGenerales/CotizacionProductoGeneral';
import AutorizarCotizacionesProductosGenerales from './pages/AutorizarCotizacionesProductosGenerales/AutorizarCotizacionesProductosGenerales';

import AutorizarRequisicionGeneral from './pages/AutorizarRequisicionGeneral/AutorizarRequisicionGeneral';

import CatalogoNomina from './pages/CatalogoNomina/CatalogoNomina';
import CatalogoNominaVer from './pages/CatalogoNominaVer/CatalogoNominaVer';
import CatalogoAsignarUsuarios from './pages/CatalogoAsignarUsuarios/CatalogoAsignarUsuarios';
import CatalogoSalarios from './pages/CatalagoSalarios/CatalagoSalarios';

import CatalogoInventarioAlmacenGenerales from './pages/CatalogoInventarioAlmacenGenerales/CatalogoInventarioAlmacenGenerales'
import CatalogoInventarioEquiposHerramientas from './pages/CatalogoInventarioEquiposHerramientas/CatalogoInventarioEquiposHerramientas'
import CatalogoProductosActios from './pages/CatalogoProductosActivos/CatalogoProductosActivos';
import CatalogoAlmacenProductosActivos from './pages/AlmacenProductosActivos/AlmacenProductosActivos';
import CatalogoValesCombustibles from './pages/CatalogoValesCombustible/CatalogoValesCombustible';
import CatalogoIEPS from './pages/CatalogoIEPS/CatalogoIEPS';

import CatalogoCuadrillas from './pages/CatalogoCuadrillas/CatalogoCuadrillas';
import CatalogoOrdenCosecha from './pages/CatalogoOrdenCosecha/CatalogoOrdenCosecha';
import DetallesOrdenCosecha from './pages/CatalogoOrdenCosecha/DetallesOrdenCosecha';
import SeguridadOrdenCosecha from './pages/CatalogoOrdenCosecha/SeguridadOrdenCosecha';
import CatalogoReportes from './pages/CatalogoReportes/CatalogoReportes';
import CatalogoReportesDetalles from './pages/CatalogoReportesDetalles/CatalogoReportesDetalles';
import CatalogoBitacoraCosecha from './pages/CatalogoBitacoraCosecha/CatalogoBitacoraCosecha';
import DetallesBitacoraCosecha from './pages/CatalogoBitacoraCosecha/DetallesBitacoraCosecha';

import CatalogoBitacoraCuadrillas from './pages/CatalogoESCuadrillas/CatalogoBitacoraCuadrillas';
import EditarBitacoraESCuadrillas from './pages/CatalogoESCuadrillas/EditarBitacoraESCuadrillas';

import CatalogoBitacoraTraslados from './pages/CatalogoBitacoraTraslados/CatalogoBitacoraTraslados';
import DetalleBitacoraTraslados from './pages/CatalogoBitacoraTraslados/DetalleBitacoraTraslados';

import EditarRequisicionServicios from './pages/RequisicionServicios/EditarRequisicionServicios';
import CatalogoRequisicionesServicios from './pages/CatalogoRequisicionesServicios/CatalogoRequisicionesServicios';
import CotizacionServicios from './pages/CotizacionesServicios/CotizacionServicios';
import AutorizarRequisicionServicios from './pages/AutorizarRequisicionServicios/AutorizarRequisicionServicios';

import CatalogoBitacoraRecepcionFruta from "./pages/CatalogoBitacoraRecepcionFruta/CatalogoBitacoraRecepcionFruta"
import CatalogoTipoRequisicionServicios from './pages/CatalogoTipoRequesicionServicios/CatalogoTipoRequesicionServicios';

import CatalogoCorridaLimon from './pages/CatalogoCorridaLimon/CatalogoCorridaLimon';
import DetallesCorridaLimon from './pages/CatalogoCorridaLimon/DetallesCorridaLimon';

import CatalogoCotizacionNaranja from './pages/CatalogoCotizacionNaranja/CatalogoCotizacionNaranja';
import DetallesCotizacionNaranja from './pages/CatalogoCotizacionNaranja/DetallesCotizacionNaranja';

import CatalogoCotizacionToronja from './pages/CatalogoCotizacionToronja/CatalogoCotizacionToronja';
import DetallesCotizacionToronja from './pages/CatalogoCotizacionToronja/DetallesCotizacionToronja';

import CatalogoVentas from './pages/CatalogoVentas/CatalogoVentas';
import CatalogoNominaSeguridad from './pages/CatalogoNominaSeguridad/CatalogoNominaSeguridad';
import CatalogoNominaSeguridadVer from './pages/CatalogoNominaSeguridadVer/CatalogoNominaSeguridadVer';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

function App() {

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>

      <Switch>
        <Route path="/catalogoclientes" component={CatalogoClientes} />
        <Route path="/catalogoproveedores" component={CatalogoProveedores} />
        <Route path="/catalogoplagas" component={CatalogoPlagas} />
        <Route path="/catalogopresentacionproductos" component={CatalogoPresentacionProducto} />
        <Route path="/catalogounidadesdemedida" component={CatalogoUnidadesDeMedida} />
        <Route path="/catalogoareastrabajo" component={CatalogoAreasTrabajo} />
        <Route path="/catalogodocumentos" component={CatalogoDocumentos} />

        <Route path="/catalogoreportes" component={CatalogoReportes} />
        <Route path="/catalogoreportesdetalles" component={CatalogoReportesDetalles} />
        <Route path="/catalogotipoclientes" component={CatalogoTipoClientes} />
        <Route path="/catalogotipodocumentos" component={CatalogoTipoDocumentos} />
        <Route path="/catalogotipoproveedores" component={CatalogoTipoProveedores} />
        <Route path="/catalogotipousuarios" component={CatalogoTipoUsuarios} />
        <Route path="/catalogotipoagroquimicos" component={CatalogoTipoProductos} />
        <Route path="/catalogotipoplagas" component={CatalogoTipoPlagas} />
        <Route path="/catalogotipocultivos" component={CatalogoTipoCultivos} />
        <Route path="/catalogocultivos" component={CatalogoCultivos} />
        <Route path="/catalogoingredientesactivos" component={CatalogoIngredientesActivos} />
        <Route path="/catalogopuestostrabajo" component={CatalogoPuestosTrabajo} />
        <Route path="/catalogoagroquimicos" component={CatalogoProductos} />
        <Route path="/catalogoalertasalmacen" component={CatalogoAlertasAlmacen} />

        <Route path="/catalogoalmacenes" component={CatalogoAlmacenes} />
        <Route path="/catalogotiposuelo" component={CatalogoTipoSuelo} />
        <Route path="/catalogolotescultivo" component={CatalogoLotesCultivo} />

        <Route path="/catalogopatronesinjerto" component={CatalogoPatronesInjerto} />
        <Route path="/catalogoranchoscultivo" component={CatalogoRanchosCultivo} />


        <Route path="/agroquimico" component={CatalogoProducto} />
        <Route path="/cliente" component={Cliente} />
        <Route path="/perfilusuario" component={PerfilUsuario} />

        <Route path="/catalogotipomaquinaria" component={CatalogoTipoMaquinaria} />
        <Route path="/catalogocombustible" component={CatalogoCombustible} />
        <Route path="/catalogomaquinaria" component={CatalogoMaquinarias} />
        <Route path="/catalogonivelesautorizacion" component={CatalogoNivelesAutorizacion} />
        <Route path="/cotizaciones" component={Cotizacion} />
        <Route path="/rectificaragroquimicos" component={RectificarProductos} />
        <Route path="/catalogoordentrabajo" component={CatalogoOrdenesTrabajo} />


        <Route path="/catalogofamiliaagroquimicos" component={CatalogoFamiliaProductos} />

        <Route path="/proveedor" component={VentanaProveedor} />

        <Route path="/catalogosolicitudminventario" component={CatalogoSolicitudMInventario} />
        <Route path="/catalogoestatussiembra" component={CatalogoEstatusSiembra} />
        <Route path="/catalogozonacultivo" component={CatalogoZonaCultivo} />
        <Route path="/catalogotablacultivo" component={CatalogoTablaCultivo} />

        <Route path="/catalogoinventarioalmacen" component={CatalogoInventarioAlmacen} />


        <Route path="/catalogonominaver" component={CatalogoNominaVer} />
        <Route path="/catalogonomina" component={CatalogoNomina} />
        <Route path="/catalogousuarios" component={CatalogoUsuarios} />
        <Route path="/detallesusuario" component={DetallesUsuario} />
        <Route path="/catalogoasignarusuarios" component={CatalogoAsignarUsuarios} />
        <Route path="/catalagosalarios" component={CatalogoSalarios} />

        <Route path="/catalogotiporequisicion" component={CatalogoTipoRequesicion} />
        <Route path="/catalogotipoactividades" component={CatalogoTipoActividades} />

        <Route path="/catalogoactividades" component={CatalogoActividades} />
        <Route path="/catalogocompras" component={CatalogoCompras} />
        <Route path="/catalogotipoproductogeneral" component={CatalogoTipoProductosGeneral} />

        <Route path="/catalogorequisicionesagroquimicos" component={CatalogoRequisiciones} />
        <Route path="/editarrequisicionagroquimico" component={CatalogoNuevaRequisicion} />
        <Route path="/requisicionagroquimico" component={Requisicion} />

        <Route path="/catalogotipoequipos" component={CatalogoTipoEquipos} />
        <Route path="/catalogoequipos" component={CatalogoEquipos} />

        <Route path="/catalogoalmacenescombustible" component={CatalogoAlmacenesCombustible} />
        <Route path="/catalogoalmacenesgenerales" component={CatalogoAlmacenProductosGenerales} />
        <Route path="/catalogoalmacenesequiposyherramientas" component={CatalogoAlmacenEquiposHerramientas} />
        <Route path="/almacencombustible" component={AlmacenCombustible} />

        <Route path="/autorizarcotizaciones" component={AutorizarCotizaciones} />
        <Route path="/propuestaanual" component={PropuestaAnual} />

        <Route path="/demonio" component={Demonio} />


        <Route path="/catalogoplananual" component={CatalogoPlanAnual} />


        <Route path="/catalogopresupuestos" component={CatalogoPlanAnual} />

        <Route path="/ordentrabajo" component={OrdenTrabajo} />
        <Route path="/reporteordentrabajo" component={ReporteOrdenTrabajo} />
        <Route path="/bienvenido" className='bienvenida' component={Bienvenido} />

        <Route path="/catalogoproductosgenerales" component={CatalogoProductosGenerales} />
        <Route path="/catalogotiporequisiciongeneral" component={CatalogoTipoRequesicionGeneral} />
        <Route path="/cotizacionproductogeneral" component={CotizacionProductoGeneral} />
        <Route path="/autorizarcotizacionesproductogeneral" component={AutorizarCotizacionesProductosGenerales} />

        <Route path="/catalogorequisicionesgenerales" component={CatalogoRequisicionesGenerales} />
        <Route path="/editarrequisiciongeneral" component={RequisicionGeneral} />
        <Route path="/autorizarrequisiciongeneral" component={AutorizarRequisicionGeneral} />

        <Route path="/catalogoinventarioalmacengenerales" component={CatalogoInventarioAlmacenGenerales} />
        <Route path="/catalogoinventarioequiposherramientas" component={CatalogoInventarioEquiposHerramientas} />

        
        <Route path="/catalogoalmacenesproductosactivos" component={CatalogoAlmacenProductosActivos} />
        <Route path="/inventarioproductosactivos" component={CatalogoProductosActios} />

        <Route path="/catalogovalescombustibles" component={CatalogoValesCombustibles} />
        <Route path="/catalogoieps" component={CatalogoIEPS} />

        <Route path="/catalogocuadrillas" component={CatalogoCuadrillas} />
        <Route path="/catalogoordencosecha" component={CatalogoOrdenCosecha} />
        <Route path="/detallesordencosecha" component={DetallesOrdenCosecha} />
        <Route path="/seguridadordencosecha" component={SeguridadOrdenCosecha} />

        
        <Route path="/catalogobitacoracosecha" component={CatalogoBitacoraCosecha} /> 
        <Route path="/detallesbitacorcosecha" component={DetallesBitacoraCosecha} />


        <Route path="/catalogobitacoracuadrillas" component={CatalogoBitacoraCuadrillas} />
        <Route path="/detallesbitacoracuadrillas" component={EditarBitacoraESCuadrillas} />

        <Route path="/catalogobitacoratraslados" component={CatalogoBitacoraTraslados} />
        <Route path="/detallebitacoratraslados" component={DetalleBitacoraTraslados} />

        <Route path="/editarrequisicionservicios" component={EditarRequisicionServicios} />
        <Route path="/catalogorequisicionesservicios" component={CatalogoRequisicionesServicios} />
        <Route path="/autorizarrequisicionservicios" component={AutorizarRequisicionServicios} />
        <Route path="/cotizacionservicios" component={CotizacionServicios} />

 
        <Route path="/catalogobitacorarecepcionfruta" component={CatalogoBitacoraRecepcionFruta} />
        <Route path="/catalogotiporequisicionservicios" component={CatalogoTipoRequisicionServicios} />

        <Route path="/catalogocorridalimon" component={CatalogoCorridaLimon} /> 
        <Route path="/detallecorridalimon" component={DetallesCorridaLimon} />

        <Route path="/catalogocotizacionnaranja" component={CatalogoCotizacionNaranja} /> 
        <Route path="/detallecotizacionnaranja" component={DetallesCotizacionNaranja} />

        <Route path="/catalogocotizaciontoronja" component={CatalogoCotizacionToronja} /> 
        <Route path="/detallecotizaciontoronja" component={DetallesCotizacionToronja} />

        <Route path="/catalogoventas" component={CatalogoVentas} />
        <Route path="/catalogonominaseguridad" component={CatalogoNominaSeguridad} />
        <Route path="/catalogonominaseguridadver" component={CatalogoNominaSeguridadVer} />
        

        <Route path="/" component={Login} />
      </Switch>
    </BrowserRouter>
  );

}

export default App;