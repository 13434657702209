export async function getInformacionOrden () {
  return informacionorden
}

export async function getTablaempleados () {
  return tablaempleados
}

export async function asignacionTablaFuncion () {
  return asignaciontabla
}

export async function getTablaMaquinaria () {
  return tablamaquinaria
}

export async function getTablaEquipo () {
  return tablaequipos
}

export async function getTablaProductos () {
  return tablaproductos
}

const informacionorden = {
  zona: 'Nombre de zona ASD',
  rancho: 'Nombre de rancho ASD',
  encargado: 'Nombre1 Nombre2 Apellido1 Apellido2',
  actividad: 'Nombre completo de la actividad',
  fecha: '12/03/2023',
  duracionestimada: '8 horas',
  participantessugeridos: '12',
  lote: 'Nombre de lote',
  tablas: '',

  descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vel pellentesque odio. Cras eu lectus luctus, mollis purus eget, venenatis ipsum. Nunc laoreet eget turpis in eleifend. Mauris non semper purus. Sed nunc quam, dictum id neque tincidunt, iaculis rhoncus nibh. Vestibulum mollis enim eu neque consectetur dapibus. Etiam feugiat massa eget dictum vestibulum. Donec eleifend odio tincidunt venenatis tempus. Donec mollis fermentum sodales. Aenean et leo euismod, hendrerit leo vitae, ullamcorper turpis. Donec consectetur arcu id tortor suscipit placerat.\n' +
  'Nullam porta orci in facilisis faucibus. Nam fringilla augue vitae nisi aliquam, in pellentesque justo imperdiet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras eget eros et nisl eleifend condimentum sed lobortis magna. Proin est ante, dapibus eget tincidunt non, suscipit ut leo. Cras vitae luctus lectus.'
}

const tablaempleados = [
  {
    "id": 1,
    "fecha": "12/04/2023",
    "nombres": "Nombre1 Nombre2",
    "apellidos": "Apellido1 Apellidos2"
  },
  {
    "id": 2,
    "fecha": "12/04/2023",
    "nombres": "Nombre1 Nombre2",
    "apellidos": "Apellido1 Apellidos2"
  },
  {
    "id": 3,
    "fecha": "12/04/2023",
    "nombres": "Nombre1 Nombre2",
    "apellidos": "Apellido1 Apellidos2"
  },
  {
    "id": 4,
    "fecha": "12/04/2023",
    "nombres": "Nombre1 Nombre2",
    "apellidos": "Apellido1 Apellidos2"
  }
]

const asignaciontabla = [
  {
    "id": 1,
    "tabla": "Nombre tabla 1",
    "estatussiembra": "Estatus siembra 1",
    "areatotal": "100",
    "plantastotales": "12000",
  },
  {
    "id": 2,
    "tabla": "Nombre tabla 2",
    "estatussiembra": "Estatus siembra 2",
    "areatotal": "101",
    "plantastotales": "12001",
  },
  {
    "id": 3,
    "tabla": "Nombre tabla 3",
    "estatussiembra": "Estatus siembra 3",
    "areatotal": "102",
    "plantastotales": "12002",
  }
]

const tablamaquinaria = [
  {
    "id": 1,
    "fecha": "12/04/2023",
    "tipomaquinaria": "Tipo maquinaria 1",
    "maquinaria": "Maquinaria 1",
    "usuario": "Nombre apellido",
    "horas": "4",
    "combustible": "12",
    "tabla": "Nombre tabla"
  },
  {
    "id": 2,
    "fecha": "12/04/2023",
    "tipomaquinaria": "Tipo maquinaria 2",
    "maquinaria": "Maquinaria 2",
    "usuario": "Nombre apellido",
    "horas": "4",
    "combustible": "12",
    "tabla": "Nombre tabla"
  },
  {
    "id": 3,
    "fecha": "12/04/2023",
    "tipomaquinaria": "Tipo maquinaria 3",
    "maquinaria": "Maquinaria 3",
    "usuario": "Nombre apellido",
    "horas": "4",
    "combustible": "12",
    "tabla": "Nombre tabla"
  }
]

const tablaequipos = [
  {
    "id": 1,
    "fecha": "12/04/2023",
    "equipo": "Tipo equipo 1",
    "tipoequipo": "Equipo 1",
    "usuario": "Nombre1 Nombre2 Apellido1 Apellido2"
  },
  {
    "id": 2,
    "fecha": "12/04/2023",
    "equipo": "Tipo equipo 2",
    "tipoequipo": "Equipo 2",
    "usuario": "Nombre1 Nombre2 Apellido1 Apellido2"
  },
  {
    "id": 3,
    "fecha": "12/04/2023",
    "equipo": "Tipo equipo 3",
    "tipoequipo": "Equipo 3",
    "usuario": "Nombre1 Nombre2 Apellido1 Apellido2"
  }
]

const tablaproductos = [
  {
    "id": 1,
    "fecha": "12/04/2023",
    "clave": "1ASDF",
    "nombre": "Nombre del producto 1",
    "unidadmedida": "kg",
    "cantidad": "20",
    "tabla": "Tabla XYZ"
  },
  {
    "id": 2,
    "fecha": "12/04/2023",
    "clave": "2ASDF",
    "nombre": "Nombre del producto 2",
    "unidadmedida": "kg",
    "cantidad": "21",
    "tabla": "Tabla XYZ"
  },
  {
    "id": 3,
    "fecha": "12/04/2023",
    "clave": "3ASDF",
    "nombre": "Nombre del producto 3",
    "unidadmedida": "kg",
    "cantidad": "22",
    "tabla": "Tabla XYZ"
  }
]
