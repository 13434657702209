import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'

import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { BsFillEyeFill } from 'react-icons/bs';
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion,
  estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla
} from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getCatalogoAlmacenesEquiposyHerramientas, getListaZonas, getListaRanchosDisponibles } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import ModalFormularioAlmacenesEquiposHerramientas from '../../Complements/Modales/ModalFormularioAlmacenesEquiposHerramientas/ModalFormularioAlmacenesEquiposHerramientas';

import { Redirect, useHistory, useLocation } from 'react-router-dom';

const todoslosfiltrosdisponibles = -1

export default class CatalogoAlmacenEquiposHerramientas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: true,
      idzonaseleccionada: todoslosfiltrosdisponibles,

      //arrayzonas: [],
      listazonas: [],
      listaranchos: [],

      tabla: {
        encabezados: [
          "Rancho",
          "Zona",
          "Descripción",
          "",
          /*"",*/
          ""
        ],
        dimensiones: [
          "15%",
          "15%",
          "46%",//"19%",
          "8%",
          /*"8%",*/
          "8%"
        ],
        mindimensiones: [
          "150px",
          "150px",
          "300px",
          "150px",
          /*"110px",*/
          "120px"
        ],
        elementos: []
      },
      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,
      errordeacceso: false,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

      redireccionandoainventario: false,

    }
  }

  async componentDidMount() {

    if (this.state.loadingspinners == false) {
      this.setState({ loadingspinners: true })
    }
    this.setState({ txtbuscador: '' })

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    var respcatalogoequipos = await getCatalogoAlmacenesEquiposyHerramientas()
    if (this.manejadorLlamadasApis(respcatalogoequipos)) {
      return;
    }
    var tabla = this.state.tabla;
    tabla.elementos = JSON.parse(JSON.stringify(respcatalogoequipos.data));

    var resplistazonas = await getListaZonas();
    if (this.manejadorLlamadasApis(resplistazonas)) {
      return;
    }
    var listazonas = JSON.parse(JSON.stringify(resplistazonas.data));

    var resplistaranchos = await getListaRanchosDisponibles()
    if (this.manejadorLlamadasApis(resplistaranchos)) {
      return;
    }
    var listaranchos = JSON.parse(JSON.stringify(resplistaranchos.data));

    this.setState({ estatus: estatus.data, tabla: tabla, listazonas: listazonas, listaranchos: listaranchos })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);

    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ idzonaseleccionada: todoslosfiltrosdisponibles })
    this.setState({ filtrolistapaginada: [] });
    this.setState({ errordeacceso: false });
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  filtrarCombo = async (idzona) => {

    await this.setState({ idzonaseleccionada: idzona })

    var filtroranchoscultivo = this.filtrarBusqueda(
      idzona, this.state.txtbuscador.trim().toLocaleLowerCase())

    if (filtroranchoscultivo.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtroranchoscultivo)

  }

  saveInputLocacion = async (e) => {

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenain < 3) && (tamanocadenast >= 3)

    this.setState({ txtbuscador: e.target.value })
    if (tamanocadenain >= 3 || banderacasosespeciales) {

      var filtroranchoscultivo = this.filtrarBusqueda(
        this.state.idzonaseleccionada, cadenain.toLocaleLowerCase())

      if (filtroranchoscultivo.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.filtro(1, filtroranchoscultivo)
    }

  }

  filtrarBusqueda = (idzona, textobusqueda) => {

    var tabla = JSON.parse(JSON.stringify(this.state.tabla))
    var filtrozonasdisponibles = tabla.elementos;

    if (idzona != todoslosfiltrosdisponibles) {
      filtrozonasdisponibles = filtrozonasdisponibles.filter((item) => item.idzona == idzona)
    }

    if (textobusqueda.length < 3) {
      return filtrozonasdisponibles
    }

    return filtrozonasdisponibles.filter(
      (item) => item.rancho.toLocaleLowerCase().includes(textobusqueda))

  }

  verModalDetalles = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo almacén de equipos', accionmodal: 1, itemseleccionado: {} })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: `Editar almacén de equipos de ${ban.contenido.rancho}`, accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }
    this.setState({ modaldetalles: true });
  }

  cerrarModal = (opciones) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (opciones.accion == 1) {
      this.actualizarTabla()
    }
  }

  filtro = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);
  }

  cambiarContenido = (pagenumber) => {
    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);
  }

  borrarBusqueda = () => {
    this.saveInputLocacion({ target: { value: '' } })
  }

  /*
    redireccionar = (idalmacenequipos) => {
      localStorage.setItem("dato", [idalmacenequipos]);
      this.setState({ redireccionando: true })
    }
  */

  redireccionando = (itemalmacen) => {
    localStorage.setItem("datos", JSON.stringify(itemalmacen.contenido));

    if (itemalmacen.ruta == "inventario") {
      this.setState({ redireccionandoainventario: true })
    } else if (itemalmacen.ruta == "alertas") {
     this.setState({ redireccionandoaalertas: true })
    }

  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionando) ? (<AccionesVista bandera={'almacenequipos'} />) : null}

        <ToastContainer />
        {(this.state.redireccionandoainventario) ? (<AccionesAlmacen bandera={'verinventario'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioAlmacenesEquiposHerramientas titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus} show={this.state.modaldetalles}
            accion={this.state.accionmodal} item={this.state.itemseleccionado} setLoadingSpinner={this.setLoadingSpinner}
            listazonas={JSON.parse(JSON.stringify(this.state.listazonas))} listaranchos={JSON.parse(JSON.stringify(this.state.listaranchos))} />
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo, minWidth: '380px' }}>Almacenes de equipos y herraminetas  </label>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona</label>
                  <div className="selectBox ">
                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                      onChange={(event) => this.filtrarCombo(event.target.value)}>
                      <option selected={this.state.idzonaseleccionada == todoslosfiltrosdisponibles} value={todoslosfiltrosdisponibles}>Todos</option>
                      {this.state.listazonas.map((i, index) => (
                        <option value={i.idcatalogo_zona} key={"tipo_" + index} selected={i.idcatalogo_zona == this.state.idzonaseleccionada} >{i.zona} </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar rancho"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>

              <div className='col containerSubTabla'></div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.trim().length < 3 && this.state.idzonaseleccionada == todoslosfiltrosdisponibles ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.zona}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                          {item.descripcion}
                        </td>
                        
                        <td className="centrarbotones letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} >
                          <label
                            onClick={this.redireccionando.bind(this, { contenido: item.idalmacenequipos, ruta: "inventario" })}
                            style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                            <BsFillEyeFill className="icoBotones" />
                            <b className='btnVerTabla'>Ver inventario </b>
                          </label>
                        </td>
                      
                        <td className="centrarbotones letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.zona}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                          {item.descripcion}
                        </td>
                       
                        <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} >
                        <label
                            onClick={this.redireccionando.bind(this, { contenido: item.idalmacenequipos, ruta: "inventario" })}
                            style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                            <BsFillEyeFill className="icoBotones" />
                            <b className='btnVerTabla'>Ver inventario </b>
                          </label>
                        </td>
                       
                        <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>

                    ))
                  }


                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 && this.state.idzonaseleccionada == todoslosfiltrosdisponibles ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangerisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}
 
function AccionesVista({ bandera }) {
  const history = useHistory();
  const location = useLocation();

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
  if (bandera == 'almacenequipos') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/catalogoinventarioequiposherramientas"} />)
  }
}

function AccionesAlmacen({ bandera }) {
  const history = useHistory();
  const location = useLocation();
  history.push(location.pathname);
  if (bandera == 'verinventario') {
    return (<Redirect from='/' to={"/catalogoinventarioequiposherramientas"} />)
  } else if (bandera == "veralertas") {
    return (<Redirect from='/' to={"/catalogoalertasalmacen"} />)
  }

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
