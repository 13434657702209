import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { MdCloudUpload } from 'react-icons/md';
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import {
    validarLetrasNumerosEspacios,
    validarSoloLetrasNumeros, validarArchivoCargadoJpgJpegPng,
    validarLetrasNumerosPuntoComa, validarNumerosDecimales
} from '../../../Services/Validaciones';
import { getListaRanchosCultivoUpdate, guardarValeCombustible } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { FaCalendar } from "react-icons/fa";
import { BiSearchAlt2 } from "react-icons/bi"

import DatePicker from "react-datepicker";
import 'react-toastify/dist/ReactToastify.css';
import ModalBuscadorEmpleados from '../ModalBuscadorEmpleados/ModalBuscadorEmpleados';

const todoslosfiltrosdisponibles = -1

export default class ModalFormularioValeCombustible extends React.Component {

    constructor(props) {
        super(props)

    
        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            arrayzonas: props.arrayzonas,


            idvale: 0,

            txtfolio: "",
            vermsgerrortxtfolio: false,
            msgerrortxtfolio: "",


            fechasolicitud: "",
            txtfechasolicitud: "",
            vermsgerrortxtfechasolicitud: false,
            msgerrortxtfechasolicitud: "",


            idtipoproveedor: -1,
            vermsgerroridtipoproveedor: false,
            msgerroridtipoproveedor: "",


            idproveedor: -1,
            vermsgerroridproveedor: false,
            msgerroridproveedor: "",

            idsolicitante: -1,
            txtsolicitante: "",
            vermsgerroridsolicitante: false,
            msgerroridsolicitante: "",


            idcombustible: -1,
            vermsgerroridcombustible: false,
            msgerroridcombustible: "",



            txtlitros: "",
            vermsgerrortxtlitros: false,
            msgerrortxtlitros: "",


            idtipodestino: -1,
            vermsgerroridtipodestino: false,
            msgerroridtipodestino: "",


            idarea: -1,
            vermsgerroridarea: false,
            msgerroridarea: "",


            idzona: -1,
            vermsgerroridzona: false,
            msgerroridzona: "",


            idrancho: -1,
            vermsgerroridrancho: false,
            msgerroridrancho: "",


            idtipomaquinaria: -1,
            vermsgerroridtipomaquinaria: false,
            msgerroridtipomaquinaria: "",


            idmaquinaria: 0,
            vermsgerroridmaquinaria: false,
            msgerroridmaquinaria: "",


            txtimporte: "",
            vermsgerrortxtimporte: false,
            msgerrortxtimporte: "",


            txtconcepto: "",
            vermsgerrortxtconcepto: false,
            msgerrortxtconcepto: "",


            imagenvale: "",
            vermsgerrorimagenvale: false,
            msgerrorimagenvale: "",


            imagenticket: "",
            vermsgerrorimagenticket: false,
            msgerrorimagenticket: "",



            moveradestino: false,











            bloquearmoveralmacen: false,









            archivocargado: null,
            txtnombrearchivo: (props.accion == 2) ? ("imagen_vale_" + props.item.folio) : 'Buscar',
            vermsgerrortxtarchivo: false,
            msgerrortxtarchivo: '',


            archivoticketcargado: null,
            txtnombrearchivoticket: (props.accion == 2 && props.item.imagenticket != "") ? ("imagen_ticket_" + props.item.folio) : 'Buscar',
            vermsgerrortxtarchivoticket: false,
            msgerrortxtarchivoticket: '',





            vermodalbuscarempleados: false,



            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,


            listatiposdestinos: [
                {
                    idtipodestino: 1,
                    tipo: "Área"
                },
                {
                    idtipodestino: 2,
                    tipo: "Rancho"
                },
                {
                    idtipodestino: 3,
                    tipo: "Maquinaria/Vehículo"
                }
            ]


        }

    }

    componentDidMount() {
        //console.log("componentdidmount", this.props.accion, this.props.item)
        if (this.props.accion == 2) {
            this.setState({
                idvale: parseInt(this.props.item.idvale),
                txtfolio: this.props.item.folio,

                txtfechasolicitud: this.props.item.fechasolicitud,
                fechasolicitud: this.convertirFechaCompleta(this.props.item.fechasolicitud),
                idtipoproveedor: this.props.item.idtipoproveedor,
                idproveedor: this.props.item.idproeedor,
                idsolicitante: this.props.item.idusuario,
                txtsolicitante: this.props.item.usuario,
                idcombustible: this.props.item.idcombustible,
                txtlitros: this.props.item.litros,
                idtipodestino: this.props.item.tipodestino,
                idarea: this.props.item.idarea,
                idzona: this.props.item.idzona,
                idrancho: this.props.item.idrancho,
                idmaquinaria: this.props.item.idmaquinaria,
                idtipomaquinaria: this.props.item.idtipomaquinaria,
                txtconcepto: this.props.item.concepto,
                txtimporte: this.props.item.importe,

                //imagenvale: imagen jpeg o png,
                //imagenticket: imagen jpeg o png(opcional),
                moveralmacen: this.props.item.enalmacen,
                bloquearmoveralmacen: (this.props.item.enalmacen == 1) ? (true) : (false)
            })

        }
    }

    convertirFechaFormato(lafecha) {
        var [day, month, year] = lafecha.split("/");
        var formatofechaini = `${month}-${day}-${year}`
        var fechaactualFin = new Date(formatofechaini);

        return fechaactualFin
    }

    convertirFechaCompleta = (fecha) => {
         const fechagen = new Date();
        var [day, month, year] = fecha.split("/");
       var fechaseteada=fechagen.setFullYear(year, month -1 , day);

        return fechaseteada;
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (opciones) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(opciones);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    cambiarTipo = (item) => {
        this.setState({ [item.tipo]: item.valor })
        if (item.tipo == "idtipoproveedor") {
            this.setState({ idproveedor: -1 })
        }
        if (item.tipo == "idzona") {
            this.setState({ idrancho: -1 })
        }
        if (item.tipo == "idtipomaquinaria") {
            this.setState({ idmaquinaria: -1 })
        }

        if (item.tipo == "idtipodestino") {
            this.setState({ idarea: -1, idzona: -1, idrancho: -1, idtipomaquinaria: -1, idmaquinaria: -1 })
        }

    }

    guardarCambios = async () => {

        //   console.log("guardando");

        this.setState({ vermsgerrortxtfolio: false })
        this.setState({ vermsgerrortxtfechasolicitud: false })
        this.setState({ vermsgerroridtipoproveedor: false })
        this.setState({ vermsgerroridproveedor: false })

        this.setState({ vermsgerroridsolicitante: false })

        this.setState({ vermsgerroridcombustible: false })
        this.setState({ vermsgerrortxtlitros: false })
        this.setState({ vermsgerroridtipodestino: false })
        this.setState({ vermsgerroridarea: false })
        this.setState({ vermsgerroridzona: false })
        this.setState({ vermsgerroridrancho: false })
        this.setState({ vermsgerroridtipomaquinaria: false })
        this.setState({ vermsgerroridmaquinaria: false })

        this.setState({ vermsgerrortxtimporte: false })
        this.setState({ vermsgerrortxtconcepto: false })

        this.setState({ vermsgerrorimagenvale: false })
        this.setState({ vermsgerrorimagenticket: false })


        let validacionescorrectas = true

        //validar folio
        let longitudvalida = { longitudminima: 1, longitudmaxima: 10 };
        let txtfolio = validarSoloLetrasNumeros(this.state.txtfolio.trim(), longitudvalida)
        if (txtfolio.codigo == "invalid") {
            this.setState({ msgerrortxtfolio: txtfolio.mensaje })
            this.setState({ vermsgerrortxtfolio: true })
            validacionescorrectas = false;
        }

        //validar fecha de solicitud
        if (this.state.txtfechasolicitud == "") {
            this.setState({ msgerrortxtfechasolicitud: "Selecciona una fecha" })
            this.setState({ vermsgerrortxtfechasolicitud: true })
            validacionescorrectas = false;
        }

        //validar tipo de proveedor
        if (this.state.idtipoproveedor == todoslosfiltrosdisponibles) {
            this.setState({ msgerroridtipoproveedor: "Selecciona un tipo de proveedor" })
            this.setState({ vermsgerroridtipoproveedor: true })
            validacionescorrectas = false;
        }

        //validar  proveedor
        if (this.state.idproveedor == todoslosfiltrosdisponibles) {
            this.setState({ msgerroridproveedor: "Selecciona un proveedor" })
            this.setState({ vermsgerroridproveedor: true })
            validacionescorrectas = false;
        }

        //validar  solicitante
        if (this.state.idsolicitante == todoslosfiltrosdisponibles) {
            this.setState({ msgerroridsolicitante: "Selecciona un empleado" })
            this.setState({ vermsgerroridsolicitante: true })
            validacionescorrectas = false;
        }

        //validar  combustible
        if (this.state.idcombustible == todoslosfiltrosdisponibles) {
            this.setState({ msgerroridcombustible: "Selecciona un combustible" })
            this.setState({ vermsgerroridcombustible: true })
            validacionescorrectas = false;
        }


        //validar  litros
        longitudvalida = { longitudminima: 1, longitudmaxima: 10 };
        let txtlitros = validarNumerosDecimales(this.state.txtlitros.trim())
        if (txtlitros.codigo == "invalid") {
            this.setState({ msgerrortxtlitros: txtlitros.mensaje })
            this.setState({ vermsgerrortxtlitros: true })
            validacionescorrectas = false;
        }


        //validar  tipodestino
        if (this.state.idtipodestino == todoslosfiltrosdisponibles) {
            this.setState({ msgerroridtipodestino: "Selecciona un tipo destino" })
            this.setState({ vermsgerroridtipodestino: true })
            validacionescorrectas = false;
        }


        if (this.state.idtipodestino == 1) {
            if (this.state.idarea == todoslosfiltrosdisponibles) {
                this.setState({ msgerroridarea: "Selecciona un área" })
                this.setState({ vermsgerroridarea: true })
                validacionescorrectas = false;
            }
        }

        if (this.state.idtipodestino == 2) {
            if (this.state.idzona == todoslosfiltrosdisponibles) {
                this.setState({ msgerroridzona: "Selecciona una zona" })
                this.setState({ vermsgerroridzona: true })
                validacionescorrectas = false;
            }

            if (this.state.idrancho == todoslosfiltrosdisponibles) {
                this.setState({ msgerroridrancho: "Selecciona un rancho" })
                this.setState({ vermsgerroridrancho: true })
                validacionescorrectas = false;
            }
        }

        if (this.state.idtipodestino == 3) {
            if (this.state.idtipomaquinaria == todoslosfiltrosdisponibles) {
                this.setState({ msgerroridtipomaquinaria: "Selecciona un tipo maquinaria/vehículo" })
                this.setState({ vermsgerroridtipomaquinaria: true })
                validacionescorrectas = false;
            }

            if (this.state.idmaquinaria == todoslosfiltrosdisponibles) {
                this.setState({ msgerroridmaquinaria: "Selecciona una maquinaria/vehículo" })
                this.setState({ vermsgerroridmaquinaria: true })
                validacionescorrectas = false;
            }
        }





        //validar  importe
        if (this.state.txtimporte.trim().length != 0) {
            longitudvalida = { longitudminima: 0, longitudmaxima: 10 };
            let txtimporte = validarNumerosDecimales(this.state.txtimporte.trim())
            if (txtimporte.codigo == "invalid") {
                this.setState({ msgerrortxtimporte: txtimporte.mensaje })
                this.setState({ vermsgerrortxtimporte: true })
                validacionescorrectas = false;
            }
        }


        //validar concepto
        //if (this.state.txtconcepto.trim().length > 0) {
        longitudvalida = { longitudminima: 1, longitudmaxima: 200 };
        let txtconcepto = validarLetrasNumerosPuntoComa(this.state.txtconcepto.trim(), longitudvalida)
        if (txtconcepto.codigo == "invalid") {
            this.setState({ msgerrortxtconcepto: txtconcepto.mensaje })
            this.setState({ vermsgerrortxtconcepto: true })
            validacionescorrectas = false;
        }
        //}


        //validar  imagen vale
        if (this.state.txtnombrearchivo == "Buscar") {
            this.setState({ msgerrortxtarchivo: "Selecciona una imagen de vale" })
            this.setState({ vermsgerrortxtarchivo: true })
            validacionescorrectas = false;
        }


        if (this.props.accion == 1) {
            let archivocargado = validarArchivoCargadoJpgJpegPng(this.state.archivocargado)
            if (archivocargado.codigo == "invalid") {
                this.setState({ msgerrortxtarchivo: archivocargado.mensaje })
                this.setState({ vermsgerrortxtarchivo: true })
                validacionescorrectas = false;
            }
        }

        if (this.props.accion == 2) {

            let archivocargado = validarArchivoCargadoJpgJpegPng(this.state.archivocargado)
            if (archivocargado.codigo == "invalid") {
                this.setState({ msgerrortxtarchivo: archivocargado.mensaje })
                this.setState({ vermsgerrortxtarchivo: true })
                validacionescorrectas = false;
            }


            let archivocargadoticket = validarArchivoCargadoJpgJpegPng(this.state.archivocargadoticket)
            if (archivocargadoticket.codigo == "invalid") {
                this.setState({ msgerrortxtarchivo: archivocargadoticket.mensaje })
                this.setState({ vermsgerrortxtarchivo: true })
                validacionescorrectas = false;
            }
        }


        if (validacionescorrectas) {
            this.setLoadingSpinner(true)
            const formdata = new FormData();

            formdata.append('idvale', this.state.idvale)
            formdata.append('folio', this.state.txtfolio)
            formdata.append('fechasolicitud', this.state.txtfechasolicitud)
            formdata.append('idproveedor', this.state.idproveedor)
            formdata.append('idusuario', this.state.idsolicitante)
            formdata.append('idcombustible', this.state.idcombustible)
            formdata.append('litros', this.state.txtlitros)
            formdata.append('concepto', this.state.txtconcepto)
            formdata.append('importe', this.state.txtimporte)
            
            formdata.append('moveralmacen', (this.state.moveradestino == true) ? (1) : (0))

            formdata.append('tipodestino', this.state.idtipodestino)
            if (this.state.idtipodestino == 1) {//area
                formdata.append('destino', this.state.idarea)
            }
            if (this.state.idtipodestino == 2) {//rancho
                formdata.append('destino', this.state.idrancho)
            }
            if (this.state.idtipodestino == 3) {//maquinaria
                formdata.append('destino', this.state.idmaquinaria)
            }


            if (this.state.archivocargado) {
                formdata.append('imagenvale', this.state.archivocargado)
            }
            if (this.state.archivocargadoticket) {
                formdata.append('imagenticket', this.state.archivocargadoticket)
            }


            

            var resp = await guardarValeCombustible(formdata);

            if (resp == -1 || resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setLoadingSpinner(false)
                return;
            }
            else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
                this.setLoadingSpinner(false)
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return true
            }
            else {
                this.showModal({ accion: '1' })
            }
        }

    }

    async seleccionarArchivo(event) {
        
        try {
            event.persist();
            var archi = event.target.files[0];
            if (event.target.files.length > 0) {
                this.setState({ archivocargado: archi, txtnombrearchivo: archi.name });
            }
        } catch (error) { }
    }

    async seleccionarArchivoTicket(event) {
        
        try {
            event.persist();
            var archi = event.target.files[0];
            if (event.target.files.length > 0) {
                this.setState({ archivocargadoticket: archi, txtnombrearchivoticket: archi.name });
            }
        } catch (error) { }
    }


    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: day + '/' + month + '/' + year })
    }


    verBuscadorEmpleados = (accion) => {
        
        this.setState({ vermodalbuscarempleados: accion });
    }


    cerrarModalBuscadorEmpleados = (datos) => {

        if (datos != 0) {
            this.setState({
                idsolicitante: datos.idcatalogousuarios,
                txtsolicitante: datos.nombres + " " + datos.apellidos,
            })
        }
        this.setState({
            vermodalbuscarempleados: false,
        })
    }


    render() {
        return (

            <>


                {(this.state.vermodalbuscarempleados == true) ? (
                    <ModalBuscadorEmpleados                    
                        setLoadingSpinner={this.setLoadingSpinner}
                        show={this.state.vermodalbuscarempleados}
                        cerrarModalBuscadorEmpleados={this.cerrarModalBuscadorEmpleados}
                    />
                ) : null}


                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: '0' })} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/*//////////////////////////////////////////  folio */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Folio</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtfolio} onChange={this.escribir("txtfolio")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtfolio}</label>}

                                {this.state.vermsgerrortxtfolio ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtfolio}</p> : null}




                                {/*//////////////////////////////////////////  fehca solicitud  */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha de solicitud </p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col-auto' style={{ margin: '0px', paddings: '0px', zIndex: '3' }}>
                                        <DatePicker
                                            selected={this.state.fechasolicitud}
                                            onChange={(date) => this.cambiarFecha({ llave: "fechasolicitud", valor: date })}
                                            dateFormat="dd/MM/yyyy"
                                            locale="es"
                                            customInput={
                                                <DateButton />
                                            }
                                        />
                                    </div>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtfechasolicitud}</label>}

                                {this.state.vermsgerrortxtfechasolicitud ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtfechasolicitud}</p> : null}



                                {/*//////////////////////////////////////////  tipo proveedor */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo proveedor </p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: "auto" }}
                                                    onChange={(event) => this.cambiarTipo({ tipo: "idtipoproveedor", valor: event.target.value })}>
                                                    <option disabled={true} value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idtipoproveedor} >Seleccionar </option>
                                                    {this.props.listatiposproveedores.map((i, index) => (
                                                        <option value={i.idtipo_proveedor} key={"tipo_" + index} selected={i.idtipo_proveedor == this.state.idtipoproveedor} >{i.tipo} </option>
                                                    ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                                {this.state.vermsgerroridtipoproveedor ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerroridtipoproveedor}</p> : null}






                                {/*//////////////////////////////////////////  proveedor */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Proveedor  </p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: "auto" }}
                                                    onChange={(event) => this.cambiarTipo({ tipo: "idproveedor", valor: event.target.value })}>
                                                    <option disabled={true} value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idproveedor} >Seleccionar </option>
                                                    {this.props.listaproveedores.map((i, index) => (
                                                        (this.state.idtipoproveedor == i.idtipo_proveedor) ? (
                                                            <option value={i.idcatalogo_proveedores} key={"tipo_" + index} selected={i.idcatalogo_proveedores == this.state.idproveedor} >{i.nombre} </option>
                                                        ) : (null)

                                                    ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                                {this.state.vermsgerroridproveedor ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerroridproveedor}</p> : null}





                                {/*//////////////////////////////////////////  Solicitante */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Solicitante</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (

                                    <button class="btnActualizar letraNormal"
                                        onClick={() => this.verBuscadorEmpleados(true)}
                                        style={{
                                            color: "rgb(0, 97, 254)",
                                            backgroundColor: "white",
                                            border: "2px solid rgb(190, 189, 188)",
                                            width: "100%"
                                        }}><BiSearchAlt2 className="icoBotones" />


                                        {(this.state.idsolicitante != -1) ? (this.state.txtsolicitante) : ("Buscar empleado")}
                                    </button>



                                ) : null}

                                {this.state.vermsgerroridsolicitante ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerroridsolicitante}</p> : null}









                                {/*//////////////////////////////////////////  casos movimiento de combustible */}

                                {
                                    (this.state.bloquearmoveralmacen == false) ? (
                                        <>
                                            {/*//////////////////////////////////////////  Combustible */}
                                            <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Combustible</p>
                                            {this.state.accion == 2 || this.state.accion == 1 ? (
                                                <div className='col lblSubTitulosModal '>
                                                    <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                                        <div className="selectBox ">
                                                            <select className="cbxTexto letraNormal" style={{ zIndex: "auto" }}
                                                                onChange={(event) => this.cambiarTipo({ tipo: "idcombustible", valor: event.target.value })}>
                                                                <option disabled={true} value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idcombustible} >Seleccionar </option>
                                                                {this.props.listacombustibles.map((i, index) => (
                                                                    <option value={i.idtipocombustible} key={"tipo_" + index} selected={i.idtipocombustible == this.state.idcombustible} >{i.tipo} </option>
                                                                ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                                            {this.state.vermsgerroridcombustible ? <p className="msgError letraNormal"
                                                style={{ color: this.state.colormsgerror }}>{this.state.msgerroridcombustible}</p> : null}






                                            {/*//////////////////////////////////////////  litros */}
                                            <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Litros </p>
                                            {this.state.accion == 2 || this.state.accion == 1 ? (
                                                <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                                    type={'number'} value={this.state.txtlitros} onChange={this.escribir("txtlitros")}></input>
                                            ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtlitros}</label>}

                                            {this.state.vermsgerrortxtlitros ? <p className="msgError letraNormal"
                                                style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtlitros}</p> : null}






                                            <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo destino</p>
                                            {this.state.accion == 2 || this.state.accion == 1 ? (
                                                <div className='col lblSubTitulosModal '>
                                                    <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                                        <div className="selectBox ">
                                                            <select className="cbxTexto letraNormal" style={{ zIndex: "auto" }}
                                                                onChange={(event) => this.cambiarTipo({ tipo: "idtipodestino", valor: event.target.value })}>
                                                                <option disabled={true} value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idtipodestino} >Seleccionar </option>
                                                                {this.state.listatiposdestinos.map((i, index) => (
                                                                    <option value={i.idtipodestino} key={"tipo_destino" + i.idtipodestino} selected={i.idtipodestino == this.state.idtipodestino} >{i.tipo}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                                            {this.state.vermsgerroridtipodestino ? <p className="msgError letraNormal"
                                                style={{ color: this.state.colormsgerror }}>{this.state.msgerroridtipodestino}</p> : null}



                                            {(this.state.idtipodestino == 1) ? ( // opción área
                                                <>
                                                    {/*//////////////////////////////////////////  destino área */}
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Área </p>
                                                    {this.state.accion == 2 || this.state.accion == 1 ? (
                                                        <div className='col lblSubTitulosModal '>
                                                            <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                                                <div className="selectBox ">
                                                                    <select className="cbxTexto letraNormal" style={{ zIndex: "auto" }}
                                                                        onChange={(event) => this.cambiarTipo({ tipo: "idarea", valor: event.target.value })}>
                                                                        <option disabled={true} value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idarea} >Seleccionar </option>
                                                                        {this.props.listaareas.map((i, index) => (
                                                                            <option value={i.idcatalogo_areas} key={"tipo_area" + i.idcatalogo_areas} selected={i.idcatalogo_areas == this.state.idarea} >{i.area}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                                                    {this.state.vermsgerroridarea ? <p className="msgError letraNormal"
                                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerroridarea}</p> : null}
                                                </>
                                            ) : (null)}



                                            {(this.state.idtipodestino == 2) ? ( // ranchos
                                                <>
                                                    {/*//////////////////////////////////////////  destino zona */}
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona</p>
                                                    {this.state.accion == 2 || this.state.accion == 1 ? (
                                                        <div className='col lblSubTitulosModal '>
                                                            <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                                                <div className="selectBox ">
                                                                    <select className="cbxTexto letraNormal" style={{ zIndex: "auto" }}
                                                                        onChange={(event) => this.cambiarTipo({ tipo: "idzona", valor: event.target.value })}>
                                                                        <option disabled={true} value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idzona} >Seleccionar </option>
                                                                        {this.props.listazonas.map((i, index) => (
                                                                            <option value={i.idcatalogo_zona} key={"tipo_area" + i.idcatalogo_zona} selected={i.idcatalogo_zona == this.state.idzona} >{i.zona}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                                                    {this.state.vermsgerroridzona ? <p className="msgError letraNormal"
                                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerroridzona}</p> : null}

                                                    {/*//////////////////////////////////////////  destino rancho */}
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho </p>
                                                    {this.state.accion == 2 || this.state.accion == 1 ? (
                                                        <div className='col lblSubTitulosModal '>
                                                            <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                                                <div className="selectBox ">
                                                                    <select className="cbxTexto letraNormal" style={{ zIndex: "auto" }}
                                                                        onChange={(event) => this.cambiarTipo({ tipo: "idrancho", valor: event.target.value })}>
                                                                        <option disabled={true} value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idrancho} >Seleccionar </option>
                                                                        {this.props.listaranchos.map((i, index) => (
                                                                            (i.idzona == this.state.idzona) ? (
                                                                                <option value={i.idrancho} key={"tipo_rancho" + i.idrancho} selected={i.idrancho == this.state.idrancho} >{i.rancho}</option>
                                                                            ) : (
                                                                                null
                                                                            )

                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                                                    {this.state.vermsgerroridrancho ? <p className="msgError letraNormal"
                                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerroridrancho}</p> : null}
                                                </>
                                            ) : (null)}




                                            {(this.state.idtipodestino == 3) ? (//maquinarias
                                                <>
                                                    {/*//////////////////////////////////////////  destino tipo maquinaria */}
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo maquinaria </p>
                                                    {this.state.accion == 2 || this.state.accion == 1 ? (
                                                        <div className='col lblSubTitulosModal '>
                                                            <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                                                <div className="selectBox ">
                                                                    <select className="cbxTexto letraNormal" style={{ zIndex: "auto" }}
                                                                        onChange={(event) => this.cambiarTipo({ tipo: "idtipomaquinaria", valor: event.target.value })}>
                                                                        <option disabled={true} value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idtipomaquinaria} >Seleccionar </option>
                                                                        {this.props.listatipomaquinarias.map((i, index) => (
                                                                            <option value={i.idtipomaquinaria} key={"tipo_area" + i.idtipomaquinaria} selected={i.idtipomaquinaria == this.state.idtipomaquinaria} >{i.tipo}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                                                    {this.state.vermsgerroridtipomaquinaria ? <p className="msgError letraNormal"
                                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerroridtipomaquinaria}</p> : null}

                                                    {/*//////////////////////////////////////////  destino maquinaria */}
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Maquinaria </p>
                                                    {this.state.accion == 2 || this.state.accion == 1 ? (
                                                        <div className='col lblSubTitulosModal '>
                                                            <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                                                <div className="selectBox ">
                                                                    <select className="cbxTexto letraNormal" style={{ zIndex: "auto" }}
                                                                        onChange={(event) => this.cambiarTipo({ tipo: "idmaquinaria", valor: event.target.value })}>
                                                                        <option disabled={true} value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idmaquinaria} >Seleccionar </option>
                                                                        {this.props.listamaquinarias.map((i, index) => (
                                                                            (i.idtipomaquinaria == this.state.idtipomaquinaria && i.idestatus == 1) ? (
                                                                                <option value={i.idmaquinaria} key={"tipo_maquinaria" + i.idmaquinaria} selected={i.idmaquinaria == this.state.idmaquinaria} >{i.nombreclave}</option>
                                                                            ) : (null)

                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                                                    {this.state.vermsgerroridmaquinaria ? <p className="msgError letraNormal"
                                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerroridmaquinaria}</p> : null}
                                                </>
                                            ) : (null)}



                                        </>



                                    ) : (
                                        <>

                                            {/*//////////////////////////////////////////  Combustible */}
                                            <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Combustible</p>
                                            <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, }}>
                                                {this.props.listacombustibles.map((i, index) => (
                                                    i.idtipocombustible == this.state.idcombustible ? (i.tipo) : (null)
                                                ))
                                                }
                                            </label>

                                            {/*//////////////////////////////////////////  litros */}
                                            <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Litros </p>
                                            <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, }}>{this.state.txtlitros}</label>


                                            {/*//////////////////////////////////////////  tipo destino */}
                                            <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo destino </p>
                                            <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, }}>
                                                {this.state.listatiposdestinos.map((i, index) => (
                                                    i.idtipodestino == this.state.idtipodestino ? (i.tipo) : (null)
                                                ))}
                                            </label>

                                            {(this.state.idtipodestino == 1) ? ( // opción área
                                                <>
                                                    {/*//////////////////////////////////////////  destino área */}
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Área </p>
                                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, }}>
                                                        {this.props.listaareas.map((i, index) => (
                                                            i.idcatalogo_areas == this.state.idarea ? (i.area) : (null)
                                                        ))}
                                                    </label>
                                                </>
                                            ) : (null)}

                                            {(this.state.idtipodestino == 2) ? ( // ranchos
                                                <>
                                                    {/*//////////////////////////////////////////  destino zona */}
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona </p>
                                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, }}>
                                                        {this.props.listazonas.map((i, index) => (
                                                            i.idcatalogo_zona == this.state.idzona ? (i.zona) : (null)
                                                        ))}
                                                    </label>

                                                    {/*//////////////////////////////////////////  destino rancho */}
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho </p>
                                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, }}>
                                                        {this.props.listaranchos.map((i, index) => (
                                                            i.idrancho == this.state.idrancho ? (i.rancho) : (null)
                                                        ))}
                                                    </label>
                                                </>
                                            ) : (null)}

                                            {(this.state.idtipodestino == 3) ? (//maquinarias
                                                <>
                                                    {/*//////////////////////////////////////////  destino tipo maquinaria */}
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo maquinaria</p>
                                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, }}>
                                                        {this.props.listatipomaquinarias.map((i, index) => (
                                                            i.idtipomaquinaria == this.state.idtipomaquinaria ? (
                                                                i.tipo
                                                            ) : (null)
                                                        ))}
                                                    </label>

                                                    {/*//////////////////////////////////////////  destino maquinaria */}
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Maquinaria </p>
                                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, }}>
                                                        {this.props.listamaquinarias.map((i, index) => (
                                                            i.idmaquinaria == this.state.idmaquinaria ? (i.nombreclave) : (null)
                                                        ))}
                                                    </label>
                                                </>
                                            ) : (null)}



                                        </>
                                    )
                                }











                                {/*//////////////////////////////////////////  importe */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Importe</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'number'} value={this.state.txtimporte} onChange={this.escribir("txtimporte")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtimporte}</label>}

                                {this.state.vermsgerrortxtimporte ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtimporte}</p> : null}




                                {/*//////////////////////////////////////////  concepto */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Concepto</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtconcepto} onChange={this.escribir("txtconcepto")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtconcepto}</label>}

                                {this.state.vermsgerrortxtconcepto ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtconcepto}</p> : null}











                                {/*//////////////////////////////////////////  imagen de vale */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Imagen de vale (jpg/jpeg/png)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='centrarBotonFile' >
                                        <input type="file" name="file-2[]" id="file-2" className="inputFileP inputFile inputFile-2" accept=".jpg, .jpeg, .png"
                                            onChange={(event) => this.seleccionarArchivo(event)} />
                                        <label for="file-2"><MdCloudUpload />
                                            <span className='letraNormal'>{this.state.txtnombrearchivo}</span>
                                        </label>
                                    </div>
                                ) : (
                                    <div className='centrarBotonFile' >
                                        <input type="file" name="file-2[]" id="file-2" className="inputFileP inputFile inputFile-2" accept=".jpg, .jpeg, .png" disabled />
                                        <label for="file-2"><MdCloudUpload />
                                            <span className='letraNormal'>{this.state.txtnombrearchivo}</span>
                                        </label>
                                    </div>
                                )}

                                {this.state.vermsgerrortxtarchivo ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtarchivo}</p> : null}
                                <br />







                                {/*//////////////////////////////////////////  imagen de ticket */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Imagen de ticket (jpg/jpeg/png)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='centrarBotonFile' >
                                        <input type="file" name="file-1[]" id="file-1" className="inputFileP inputFile inputFile-2" accept=".jpg, .jpeg, .png"
                                            onChange={(event) => this.seleccionarArchivoTicket(event)} />
                                        <label for="file-1"><MdCloudUpload />
                                            <span className='letraNormal'>{this.state.txtnombrearchivoticket}</span>
                                        </label>
                                    </div>
                                ) : (
                                    <div className='centrarBotonFile' >
                                        <input type="file" name="file-2[]" id="file-2" className="inputFileP inputFile inputFile-2" accept=".jpg, .jpeg, .png" disabled />
                                        <label for="file-2"><MdCloudUpload />
                                            <span className='letraNormal'>{this.state.txtnombrearchivoticket}</span>
                                        </label>
                                    </div>
                                )}

                                {this.state.vermsgerrortxtarchivoticket ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtarchivoticket}</p> : null}
                                <br />









                                {/*//////////////////////////////////////////  sección de mover a destino */}

                                {
                                    (this.state.bloquearmoveralmacen == false) ? (
                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                                            <div className="modalDetallesCheck" onClick={() => this.setState({ moveradestino: !this.state.moveradestino })}>
                                                <input type="checkbox" checked={this.state.moveradestino} /> Mover a destino
                                            </div>
                                        </p>
                                    ) : (
                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                                            Movido a destino indicado
                                        </p>
                                    )
                                }

                            </div>

                        </div>

                        {this.state.accion == 2 && this.props.item.facturado != 1 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal({ accion: '0' })}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}








const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{
            backgroundColor: 'transparent', padding: '0px 0px 0px 0px', borderRadius: '0px',
            borderBottom: '2px solid #868686', width: '96%', margin: '0px 8px 18px 8px'
        }}
    >
        <label style={{ width: '90%', textAlign: 'left', cursor: 'pointer' }}>{value ? value : 'dd/mm/aaaa'}</label>
        <FaCalendar className="icoBotones" style={{ minWidth: '28px' }} />
    </button>
));
