import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './RequisicionGeneral.css';
import { Button } from 'reactstrap';
import { IoMdClose } from 'react-icons/io';
import { FaCalendar } from 'react-icons/fa'
import { coloresTxtBuscador, estiloColorError, estiloBtnActualizar, estiloBtnNuevo, estiloComboBox, estiloFiltroEncabezados, estiloBtnEditar } from '../../Services/PaletaDeColores';
import ModalFormularioGuardarRequisicion from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalFormularioGuardarRequisicion';

import ContenedorTabla from './ContenedorTabla';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {
  getComboAreasDeTrabajo,
  getListaDestinos,
  getListaAlmacenProductoGeneral, getListaAlmacenEquipos,
  guardarNuevaRequesicionGeneral, getListaProductosGenerales,
  getListaTipoRequisicionGeneral, getInformacionRequesicionGeneral,
  getListaTipoProductosGeneral, agregarProductoGeneralEmergente,

  getListaMaquinarias,

  getListaTipoRequisicion, getListaEstatusRequesicion, getInformacionRequesicion, guardarNuevaRequesicion, agregarProductoEmergente,
  getListaZonas, getListaRanchosDisponibles, getListaLotes, getListaTablaCultivos, getComboFamiliaProducto, getComboTipoProducto, getComboUnidadMedida, getListaActividades, getListaTipoActividades, buscarPeriodo
} from '../../Services/Api';
import { validarLetrasNumerosEspaciosGuionesProductoPuntoComaCaracteres } from '../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import ModalFormularioAccionEstatus from '../../Complements/Modales/ModalRequisicion/ModalFormularioAccionEstatus';

import ModalFormularioAccionEstatusContrasena from '../../Complements/Modales/ModalRequisicionGeneral/ModalFormularioAccionEstatusContrasena';

registerLocale('es', es)

export default class RequisicionGeneral extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      plananual: false,
      txtnombrerequisicion: '',
      txttiporequesicion: -1,
      txtfechaestimadacompra: '',
      txtanio: '' + new Date().getFullYear(),
      //idperiodo: '',
      txtactividad: undefined,
      txtmes: undefined,
      txtestatus: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,
      redireccionandoarequisicion: false,

      vermsgerrortxtnombrerequisicion: false,
      msgerrortxtnombrerequisicion: "",
      vermsgerrortxttiporequesicion: false,
      msgerrortxttiporequesicion: "",
      vermsgerrortxtfechaestimadacompra: false,
      msgerrortxtfechaestimadacompra: "",

      vermsgerrortxtactividad: false,
      msgerrortxtactividad: "",

      vermsgerrorplananual: false,
      msgerrorplananual: "",


      idrequisicion: -1,
      opcioneditar: false,
      estatus: [],

      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,

      cargadeinformacion: false,

      listatiporequesicion: [],
      listaestatus: [],
      zonas: [],
      listanuevosproductos: [],
      inforequisicion: {},

      requestbodyrequisicion: {},
      tablasrequestbody: [],
      stockrequestbody: {
        idstock: "0",
        productos: [
        ]
      },
      stock: {
        productos: [
        ]
      },
      stockactivo: false,
      productosenstockvalidos: false,
      productosentablasvalidos: false,

      confirmarenvio: false,
      tablaresumen: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          "Proveedor",
          ""
        ],
        dimensiones: [
          "30%",
          "20%",
          "15%",
          "30%",
          "5%",
        ],
        mindimensiones: [
          "130px",
          "170px",
          "120px",
          "120px",
          "30px",
        ],
        elementos: [
        ],
        total: ""
      },
      productoresumenseleccionado: undefined,
      modalguardar: false,
      anioseleccionado: new Date().getFullYear(),

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,



      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      colormsgerror: estiloColorError().msgerror,

      //lista catálogos
      listazonas: [],
      listaranchos: [],
      listalotes: [],
      listatablas: [],
      listaproductos: [],
      listafamiliaproductos: [],
      listatipoproductos: [],
      listaunidadesmedida: [],
      listaactividades: [],
      listatipoactividad: [],
      productosresumeninfo: [],

      listaareas: [],
      listadestinos: [],
      listavehiculos: [],
      listaalamcenproductosgenerales: [],
      listaalmacenequipos: [],

      fechaestimadacompra: new Date(),

      listaproductosgeneralesagregados: [],
      listaguardados: {},
      listadestinosauxiliar: {},
      redireccionando: false,
      estatuseditable: false,
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (localStorage.getItem('req') == null || localStorage.getItem('req') == undefined) {
      this.setState({ salir: true })
    }

    this.setState({
      opcioneditar: JSON.parse(localStorage.getItem("req")).opcion,
      idrequisicion: JSON.parse(localStorage.getItem("req")).idreq
    })
    var resptiporequesicion = await getListaTipoRequisicionGeneral();
    if (this.manejadorLlamadasApis(resptiporequesicion)) {
      return;
    }

    var resplistaestatusrequisicion = await getListaEstatusRequesicion();
    if (this.manejadorLlamadasApis(resplistaestatusrequisicion)) {
      return;
    }

    
    var resplistadestinos = await getListaDestinos();
    if (this.manejadorLlamadasApis(resplistadestinos)) {
      return;
    }

    //************************************************** */
    //Se carga la información necesaria para los modales
  /*  

    

    var resplistaactividades = await getListaActividades();
    if (this.manejadorLlamadasApis(resplistaactividades)) {
      return;
    }

    var resplistatipoactividades = await getListaTipoActividades();
    if (this.manejadorLlamadasApis(resplistatipoactividades)) {
      return;
    }

   

    //getListaAlmacenProductoGeneral, getListaAlmacenEquipos
   
    */
    //************************************************** */

    if (this.state.opcioneditar && !this.state.cargadeinformacion) {

      var inforequisicion = await getInformacionRequesicionGeneral({
        idrequisicion: this.state.idrequisicion
      });
      if (this.manejadorLlamadasApis(inforequisicion)) {
        return;
      }

      var [year, month, day] = inforequisicion.data.fechaestimada_entrega.split("/");
      var fecha = day + "-" + month + "-" + year;
      var listaguardados = this.state.listaguardados
      var listadestinosauxiliar = this.state.listadestinosauxiliar
      inforequisicion.data.productos.forEach(element => {
        listaguardados[element.idproducto] = true

        var destinoseleccionado = {}
        destinoseleccionado.nombre = element.descripcion
        destinoseleccionado.iddestino = element.idalmacengenerales
        destinoseleccionado.idtipo_destino = element.idtipodestino
        listadestinosauxiliar[element.idproducto] = {
          nombre: element.destino,
          iddestino: element.iddestino,
          idtipo_destino: element.idtipodestino
        }
      })
      await this.setState({
        inforequisicion: inforequisicion.data,
        cargadeinformacion: true,
        listaproductosgeneralesagregados: inforequisicion.data.productos,

        listaguardados: listaguardados,
        listadestinosauxiliar: listadestinosauxiliar
        //productosresumeninfo: inforequisicion.data.Resumen.productos
      })
    }

    // Define si los botones de Aprobar o Rechazar requisición se incluirán
    //var estatuseditable = inforequisicion.data.idestatusrequisicion == 2

    await this.setState({
      //estatuseditable: estatuseditable,
      listatiporequesicion: resptiporequesicion.data,
      listaestatus: resplistaestatusrequisicion.data,
      listadestinos: resplistadestinos.data,
     /*
     
     
      listaactividades: resplistaactividades.data,
      listatipoactividad: resplistatipoactividades.data,
    
    
     */
    })
    this.cambiarFecha({ llave: "fechaestimadacompra", valor: this.state.fechaestimadacompra });
    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }else if (resp.code == 200) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }


  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  validarDatos = async () => {
    var validacionescorrectas = true;
    this.setState({ vermsgerrortxtnombrerequisicion: false })
    this.setState({ vermsgerrortxttiporequesicion: false })
    this.setState({ vermsgerrortxtfechaestimadacompra: false })
    this.setState({ vermsgerrorplananual: false })
    this.setState({ vermsgerrortxtactividad: false })

    if (!this.state.opcioneditar) {
      let longitudcincuenta = { longitudminima: 1, longitudmaxima: 200 };
      let nombre = validarLetrasNumerosEspaciosGuionesProductoPuntoComaCaracteres(this.state.txtnombrerequisicion.trim(), longitudcincuenta);
      if (nombre.codigo == "invalid") {
        this.setState({ msgerrortxtnombrerequisicion: nombre.mensaje })
        this.setState({ vermsgerrortxtnombrerequisicion: true })
        validacionescorrectas = false;
      }

      if (this.state.txttiporequesicion == -1 || this.state.txttiporequesicion == "seleccionar") {
        this.setState({ msgerrortxttiporequesicion: "Selecciona un tipo de requisición" })
        this.setState({ vermsgerrortxttiporequesicion: true })
        validacionescorrectas = false;
      }

      if (this.state.txtfechaestimadacompra == "" || this.state.txtfechaestimadacompra == null) {
        this.setState({ msgerrortxtfechaestimadacompra: "Selecciona una fecha" })
        this.setState({ vermsgerrortxtfechaestimadacompra: true })
        validacionescorrectas = false;
      }

    }


    //var listadestinosauxiliar = this.state.listadestinosauxiliar
    var listaguardados = this.state.listaguardados
    var listaproductosgeneralesagregados = this.state.listaproductosgeneralesagregados
    listaproductosgeneralesagregados.forEach(element => {
      validacionescorrectas = validacionescorrectas && listaguardados[element.idproducto]
    })

    if (listaproductosgeneralesagregados.length < 1) {
      validacionescorrectas = false
    }

    if (validacionescorrectas) {
      //var productosresumen = this.obtenerListaTablaResumen();
      var requestbody = {};
      requestbody = {
        idcatalogo_requisicion: this.state.opcioneditar ? this.state.inforequisicion.idcatalogo_requisicion : 0,
        nombre: this.state.opcioneditar ? this.state.inforequisicion.nombre : this.state.txtnombrerequisicion,
        idtiporequisicion: this.state.opcioneditar ? this.state.inforequisicion.idtiporequisicion : this.state.txttiporequesicion,
        fechaestimada_entrega: this.state.opcioneditar ? this.state.inforequisicion.fechaestimada_entrega : this.state.txtfechaestimadacompra,
        productos: this.state.listaproductosgeneralesagregados//productosresumen
      }

      await this.setState({ requestbodyrequisicion: requestbody });
      this.verModalGuardar({ opcion: "1" })    
    } else {
      toast.info("Datos incompletos", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
    }


  }


  validarProductos = async () => {
    var listazonaauxiliar = [...this.state.zonas];
    var productosvalidos = true;
    await this.setState({
      productosenstockvalidos: true,
      productosentablasvalidos: true
    })

    if (this.state.stockactivo) {
      if (this.state.stock.productos.length > 0) {
        for (let index = 0; index < this.state.stock.productos.length; index++) {
          var producto = this.state.stock.productos[index];

          if (producto.cantidad <= 0 || producto.cantidad == "") {
            await this.setState({
              productosenstockvalidos: false
            })
            return productosvalidos = false;
          }

        }
      } else {
        await this.setState({
          productosenstockvalidos: false
        })
        return productosvalidos = false;
      }
    }

    if (listazonaauxiliar.length > 0) {
      for (let index = 0; index < listazonaauxiliar.length; index++) {
        var zona = listazonaauxiliar[index];
        if (zona.ranchos.length > 0) {
          for (let index = 0; index < zona.ranchos.length; index++) {
            var rancho = zona.ranchos[index];
            if (rancho.lotes.length > 0) {
              for (let index = 0; index < rancho.lotes.length; index++) {
                var lote = rancho.lotes[index];
                if (lote.tablas.length > 0) {
                  for (let index = 0; index < lote.tablas.length; index++) {
                    var tabla = lote.tablas[index];
                    if (tabla.productos.length > 0) {
                      for (let index = 0; index < tabla.productos.length; index++) {
                        var producto = tabla.productos[index];

                        if (producto.cantidad <= 0 || producto.cantidad == "") {
                          await this.setState({
                            productosentablasvalidos: false
                          })
                          return productosvalidos = false;
                        }

                      }
                    } else {
                      await this.setState({
                        productosentablasvalidos: false
                      })
                      return productosvalidos = false;
                    }
                  }
                } else {
                  await this.setState({
                    productosentablasvalidos: false
                  })
                  return productosvalidos = false;
                }
              }
            } else {
              await this.setState({
                productosentablasvalidos: false
              })
              return productosvalidos = false;
            }
          }
        } else {
          await this.setState({
            productosentablasvalidos: false
          })
          return productosvalidos = false;
        }

      }
    } else {
      await this.setState({
        productosentablasvalidos: false
      })
      return productosvalidos = false;
    }

    return productosvalidos;

  }

  obtenerListaTablaResumen() {
    var listaproductos = [];
    this.state.tablaresumen.elementos.forEach(producto => {
      var productoaux = {
        "idproducto": producto.idproducto,
        "cantidad": producto.cantidad,
        "idproveedorsugerido": producto.idproveedorsugerido
      }
      listaproductos.push(productoaux)
    });
    return listaproductos;
  }
  obtenerListaProductosStockResumen() {
    var listaproductos = [];
    this.state.stockrequestbody.productos.forEach(producto => {
      var productoaux = {
        "idproducto": producto.idproducto,
        "cantidad": producto.cantidad,
      }
      listaproductos.push(productoaux)
    });
    return listaproductos;
  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Zonas disponibles', accionmodal: 1 })
    }

    this.setState({ modaldetalles: true });
  }

  verModalGuardar = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Solicitar autorización', accionmodal: 1 })
    }

    this.setState({ modalguardar: true });
  }

  cerrarModal = (listazonas) => {
    var listazonasagregadasaux = this.state.zonas;
    listazonasagregadasaux = listazonasagregadasaux.concat(listazonas)
    this.setState({
      modaldetalles: !this.state.modaldetalles,
      zonas: listazonasagregadasaux
    })

  }

  cancelarFormulario = async () => {
    if (this.state.opcioneditar) {

      var inforequisicion = await getInformacionRequesicionGeneral({
        idrequisicion: this.state.idrequisicion
      });
      if (this.manejadorLlamadasApis(inforequisicion)) {
        return;
      }

      var listaguardados = this.state.listaguardados
      var listadestinosauxiliar = this.state.listadestinosauxiliar
      inforequisicion.data.productos.forEach(element => {
        listaguardados[element.idproducto] = true

        var destinoseleccionado = {}
        destinoseleccionado.nombre = element.descripcion
        destinoseleccionado.iddestino = element.idalmacengenerales
        destinoseleccionado.idtipo_destino = element.idtipodestino
        listadestinosauxiliar[element.idproducto] = {
          nombre: element.destino,
          iddestino: element.iddestino,
          idtipo_destino: element.idtipodestino
        }
      })
      await this.setState({
        inforequisicion: inforequisicion.data,
        cargadeinformacion: true,
        listaproductosgeneralesagregados: inforequisicion.data.productos,

        listaguardados: listaguardados,
        listadestinosauxiliar: listadestinosauxiliar,
        //productosresumeninfo: inforequisicion.data.Resumen.productos,
        loadingspinners: false
      })
      toast.info("Se borraron los cambios recientes", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
  }

  cerrarModalGuardar = async (datos) => {

    var actualizacionids = true;
    this.setState({
      modalguardar: !this.state.modalguardar, loadingspinners: false
    })

    var request = this.state.requestbodyrequisicion;
    if (datos != 0) {
      request.solicitarautorizacion = datos.solicitarautorizacion;
      //request.idestatusrequisicion = datos.idestatusrequisicion;

      this.activarSpinner(true);

      for (let index = 0; index < this.state.listaproductosgeneralesagregados.length; index++) {
        const producto = this.state.listaproductosgeneralesagregados[index];

        if (producto.idproducto.toString().includes("nuevo")) {
          var datanuevoproducto = {
            "idcatalogo_producto_general": 0,
            "nombrecomercial": producto.producto,
            "idcatalogo_unidadmedida": producto.idcatalogo_unidadmedida
          };

          var dataproducto = await agregarProductoGeneralEmergente(datanuevoproducto)

          if (dataproducto == -1 || dataproducto.code == 1000) {
            this.activarSpinner(false);
            actualizacionids = false;
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            break;
          } else if (dataproducto.code == 200 || dataproducto.code == 300 || dataproducto.code == 400) {
            this.activarSpinner(false);
            actualizacionids = false;
            toast.error(dataproducto.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            break;
          } else {
            var indexproductoresumen = request.productos.findIndex((item => item.idproducto == producto.idproducto));
            // Actualizar ids de productos
            if (indexproductoresumen > -1) {
              request.productos[indexproductoresumen].idproducto = dataproducto.data.idcatalogo_producto_general;
            }
          }
        }
      }

      if (actualizacionids) {
        var requestbodyrequisicion = request //this.state.requestbodyrequisicion
        var productosfinales = requestbodyrequisicion.productos ? request.productos/*this.state.requestbodyrequisicion.productos*/ : []
        var productosfinales = productosfinales.map(item => {
          return {
            idproducto: item.idproducto,
            cantidad: item.cantidad,
            idtipodestino: item.idtipodestino,
            iddestino: item.iddestino
          }
        })
        requestbodyrequisicion.productos = productosfinales
        var resp = await guardarNuevaRequesicionGeneral(requestbodyrequisicion);
        if (resp == -1 || resp.code == 1000) {
          this.activarSpinner(false);
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          return;
        }
        else if (resp.code == 200 || resp.code == 300) {
          this.activarSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
        } else if (resp.code == 400) {
          this.activarSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        } else if (resp.code == 0) {
          this.activarSpinner(false);

          await this.setState({
            idrequisicion: resp.data.idcatalogo_requisicion,
            opcioneditar: true
          })

          var inforequisicion = await getInformacionRequesicionGeneral({ idrequisicion: resp.data.idcatalogo_requisicion });
          if (this.manejadorLlamadasApis(inforequisicion)) {
            return;
          }

          var listaguardados = this.state.listaguardados
          var listadestinosauxiliar = this.state.listadestinosauxiliar
          inforequisicion.data.productos.forEach(element => {
            listaguardados[element.idproducto] = true

            var destinoseleccionado = {}
            destinoseleccionado.nombre = element.descripcion
            destinoseleccionado.iddestino = element.idalmacengenerales
            destinoseleccionado.idtipo_destino = element.idtipodestino
            listadestinosauxiliar[element.idproducto] = {
              nombre: element.destino,
              iddestino: element.iddestino,
              idtipo_destino: element.idtipodestino
            }
          })


          await this.setState({
            inforequisicion: inforequisicion.data,
            cargadeinformacion: true,
            listaproductosgeneralesagregados: inforequisicion.data.productos,

            listaguardados: listaguardados,
            listadestinosauxiliar: listadestinosauxiliar,
            loadingspinners: false
          })

          toast.success("Requisición guardada con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })

        }
      } else {
        this.activarSpinner(false);
        toast.error("Error al guardar requisición", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      }

    }

  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  actualizarContenido = async (zona) => {
    var listazonas = this.state.zonas;

    var index = listazonas.findIndex((obj => obj.idzona == zona.idzona));
    if (index > -1) {
      listazonas[index] = zona;
    }

    //this.calcularResumen(listazonas);

    this.setState({
      zonas: listazonas,
    })
  }

  actualizarContenidoStock = async (productos) => {
    var stock = this.state.stock;
    stock.productos = productos
    //this.calcularResumen(this.state.zonas);

    await this.setState({
      stock: stock
    })
  }

  actualizarContenidoGeneral = async (productos, listadestinos, listaguardados) => {
    //var listaproductosgeneralesagregados = this.state.listaproductosgeneralesagregados
    var listaproductosgeneralesagregados = Object.values(productos)
    this.setState({
      listaproductosgeneralesagregados: listaproductosgeneralesagregados,
      listadestinosauxiliar: listadestinos,
      listaguardados: listaguardados
    })
  }

  actualizarListaNuevosProductos = async (nuevoproducto) => {
    var auxnuevosproductos = this.state.listanuevosproductos;
    var indexproducto = auxnuevosproductos.findIndex((item => item.idproducto == nuevoproducto.idproducto));
    if (indexproducto == -1) {
      auxnuevosproductos.push(nuevoproducto);
      await this.setState({
        listanuevosproductos: auxnuevosproductos
      })
    }
  }

  actualizarProveedoresResumen = async (listaproductosresumen) => {
    var productostablaresumen = listaproductosresumen;
    var productosresumeninfo = this.state.productosresumeninfo;
    for (let index = 0; index < productostablaresumen.length; index++) {
      var element = productostablaresumen[index];
      var indexproducto = productosresumeninfo.findIndex((item => item.idproducto == element.idproducto));
      if (indexproducto != -1) {
        element.proveedorsugerido = productosresumeninfo[indexproducto].proveedorsugerido;
        element.idproveedorsugerido = productosresumeninfo[indexproducto].idproveedorsugerido == "" ? 0 : productosresumeninfo[indexproducto].idproveedorsugerido;
      }
    }
    return productostablaresumen;
  }

  escribir = (prop) => (event) => {

    this.setState({ [prop]: event.target.value });

  }

  regresaFecha = (fecha) => {
    var fechaf = "";
    if (fecha != null) {
      var [day, month, year] = fecha.split("/");

      if (day != undefined && month != undefined && year != undefined) {
        fechaf = `${year}-${month}-${day}`
        return fechaf;
      } else {
        fechaf = fecha;
      }
    }

    return fechaf;
  }

  borrarBusqueda = () => {
    this.setState({
      txtnombrerequisicion: '',
    })
  }

  seleccionarTipoRquesicion(tipo) {
    this.setState({
      txttiporequesicion: tipo,
    })
  }

  seleccionarEstatus(estatus) {
    this.setState({
      txtestatus: estatus,
    })
  }

  cambiarFecha = (item) => {
    this.setState({ [item.llave]: item.valor })

    let day = `0${item.valor.getDate()}`.slice(-2)
    let month = `0${item.valor.getMonth() + 1}`.slice(-2)
    let year = item.valor.getFullYear()
    this.setState({ ['txt' + item.llave]: day + '/' + month + '/' + year })

    // this.consultarCatalogoCompra(['txt' + item.llave], year + '-' + month + '-' + day)
  }


  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }




  //elementos para aprobar y rechazar la requisición 
  verModalFormularioAccionEstatus = (ban) => {
    const aux = this.state.inforequisicion
    var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion/*, idestatusrequisicion: aux.idestatusrequisicion*/ }
    if (ban.id == 1) {
      this.setState({ titulomodal: 'Rechazar requisición', accionmodal: ban.id, modalaccionestatus: true, itemseleccionado: item });
    }
    else if (ban.id == 2) {
      this.setState({ titulomodal: 'Aprobar requisición', accionmodal: ban.id, modalaccionestatus: true, itemseleccionado: item });
    }
  }

  cerrarModalFormularioAccionEstatus = (opciones) => {
    this.setState({ modalaccionestatus: !this.state.modalaccionestatus })
    if (opciones.accion == 1) {
      this.verModalFormularioAccionEstatusContrasena(opciones)
    }
    else if (opciones.accion == 2) {
      this.verModalFormularioAccionEstatusContrasena({ id: 2, item: opciones.item })
    }
  }

  verModalFormularioAccionEstatusContrasena = (ban) => {
    if (ban.id == 1 && ban.accion == 1) {// 1: Rechazar requisición, 2: Aprobar requisición
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: ban.item });
    }
    else if (ban.id == 2 && ban.accion == 1) {// 1: Rechazar requisición, 2: Aprobar requisición
      const aux = this.state.inforequisicion
      var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion, idestatusrequisicion: 3 /* id de autorizar */ }
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: item });
    }
  }

  cerrarModalFormularioAccionEstatusContrasena = (opciones) => {
    this.setState({ modalaccionestatuscontrasena: !this.state.modalaccionestatuscontrasena })
    if (opciones.accion == 1) {
      this.redireccionando()
    }
  }





  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionandoarequisicion) ? (<Acciones bandera={'irarequisicion'} />) : null}
        {(this.state.redireccionando) ? (<Acciones bandera={'catalogoreq'} />) : null}
        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalguardar == true) ? (
          <ModalFormularioGuardarRequisicion titulomodal={this.state.titulomodal} cerrarModalGuardar={this.cerrarModalGuardar}
            activarSpinner={this.activarSpinner}
            show={this.state.modalguardar} accion={this.state.accionmodal} listazonasagregadas={this.state.zonas} />
        ) : null}



        {(this.state.modalaccionestatus == true) ? (
          <ModalFormularioAccionEstatus titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatus} estatus={this.state.estatus}
            show={this.state.modalaccionestatus} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        {(this.state.modalaccionestatuscontrasena == true) ? (
          <ModalFormularioAccionEstatusContrasena titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatusContrasena} estatus={this.state.estatus}
            show={this.state.modalaccionestatuscontrasena} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        <Navbar />
        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        {(this.state.opcioneditar) ?
          (<div className="container-fluid" >
            <div className="container-fluid anchoMaximo ">

              <div className='row paddingEncabezados align-items-end contenedorEncabezado'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezados'>
                  <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.inforequisicion.nombre}</label>
                </div>

                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>ID: </label>
                  <label className='lblTexto letraTitulos'>{this.state.inforequisicion.idcatalogo_requisicion}</label>
                </div>
                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Fecha estimada de entrega: </label>
                  <label className='lblTexto letraTitulos'>{this.state.inforequisicion.fechaestimada_entrega}</label>
                </div>
                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Tipo de requisición: </label>
                  <label className='lblTexto letraTitulos'>{this.state.inforequisicion.tiporequisicion}</label>
                </div>
                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Fecha de creación: </label>
                  <label className='lblTexto letraTitulos'>{this.state.inforequisicion.fechacreacion}</label>
                </div>
                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Estatus: </label>
                  <label className='lblTexto letraTitulos'>{this.state.inforequisicion.estatusrequisicion}</label>
                </div>
                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Fecha de actualización: </label>
                  <label className='lblTexto letraTitulos'>{this.state.inforequisicion.fechaactualizacion}</label>
                </div>
                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Solicitante: </label>
                  <label className='lblTexto letraTitulos'>{this.state.inforequisicion.solicitante}</label>
                </div>
                {
                  this.state.inforequisicion.comentario && this.state.inforequisicion.idestatusrequisicion != 2 ?
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-2 pr-2 seccionAviso text-center'>
                      <label className='lblTexto letraTitulos text-center'>{this.state.inforequisicion.comentario}</label>
                    </div> : null
                }

              </div>
            </div>

          </div>)
          :
          // Caso en donde es una nueva requisición
          (<div className="container-fluid" >
            <div className="container-fluid anchoMaximo ">

              <div className='row paddingEncabezados align-items-end contenedorEncabezado d-flex'>

                <br />

                <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-12 containerSubTabla paddingBottomEncabezados contenedorEncabezadoAA'>
                  <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Requisición de productos generales</label>
                </div>

                <div className='col-12 col-sm-12 col-md-12 containerSubTabla paddingBottomEncabezados'>

                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, minWidth: '90px' }}>Nombre</label>
                  <input id='Search' type="search" onChange={this.escribir("txtnombrerequisicion")} value={this.state.txtnombrerequisicion}
                    style={{ color: this.state.colortxtbuscar, maxWidth: '510px' }}
                    className="txtBuscador letraNormal inputBasico"
                    placeholder=""></input>
                  {this.state.txtnombrerequisicion ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }}
                    onClick={this.borrarBusqueda} />) : null}

                  {this.state.vermsgerrortxtnombrerequisicion ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnombrerequisicion}</p> : null}
                </div>


                <div className='col-12 containerSubTabla paddingBottomEncabezados' style={{ backgroundColor: 'blueAA' }}>

                  <div className='divComboRequisicionAA' style={{ color: this.state.colorcbxtipolbl, backgroundColor: 'yellowAA'/*this.state.colorfondotipo/*, border: this.state.colorbordertipo*/ }}>
                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, backgroundColor: 'redAA', minWidth: '160px' }}>
                      Tipo requisición:
                    </label>

                    <select className="cbxTexto letraTitulos"
                      style={{
                        color: this.state.colorcbxtipolbl, backgroundColor: 'greenaa',
                        border: 'none', borderBottom: '1px solid black', borderRadius: 0, background: 'none',
                        maxWidth: '440px'
                      }}
                      onChange={(event) => this.seleccionarTipoRquesicion(event.target.value)}>
                      {this.state.txttiporequesicion == "seleccionar" ?
                        <option selected="true" value='seleccionar'>Selecciona un tipo</option> : <option value='seleccionar'>Selecciona un tipo</option>}
                      {this.state.listatiporequesicion.map((i, index) => (
                        <option value={i.idtipo_requisicion_general} key={"tipo_" + index}  >{i.tipo} </option>
                      ))}
                    </select>
                  </div>

                  {this.state.vermsgerrortxttiporequesicion ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxttiporequesicion}</p> : null}
                </div>


                <div className='col-12 containerSubTabla paddingBottomEncabezados'>
                  <div className='contenedorFechaR' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar }}>

                    <div className='w-100 d-flex'>
                      <div className='' style={{ color: this.state.colortipolbl, minWidth: '160px' }}>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Fecha estimada de entrega:</label>
                      </div>
                      <div className='' style={{ marginLeft: '10px', minWidth: '125px' }}>
                        <div className='w-100 col-auto' style={{ margin: '0px', padding: '0px' }}>
                          <DatePicker
                            className='float-right'
                            selected={this.state.fechaestimadacompra}
                            onChange={(date) => this.cambiarFecha({ llave: "fechaestimadacompra", valor: date })}
                            dateFormat="dd/MM/yyyy"
                            locale="es"
                            customInput={
                              <DateButton />
                            }
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  {this.state.vermsgerrortxtfechaestimadacompra ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtfechaestimadacompra}</p> : null}
                </div>

              </div>
            </div>

          </div>)
        }
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}


        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
        <br />
        <br />
        <div className="container-fluid">
          <div className="container-fluid anchoMaximo">
            <div className="container-fluid">
              <div className='spacePaddingBottom'>
                <ContenedorTabla key={"tabla_stock"} accion={this.state.accion}
                  itemtabla={{ productos: this.state.listaproductosgeneralesagregados }}
                  listaguardados={this.state.listaguardados}
                  listadestinosauxiliar={this.state.listadestinosauxiliar}

                  actualizarContenidoStock={this.actualizarContenidoStock}

                  actualizarContenidoGeneral={this.actualizarContenidoGeneral}

                  actualizarListaNuevosProductos={this.actualizarListaNuevosProductos}
                  listaproductos={this.state.listaproductos}
                  listafamiliaproductos={this.state.listafamiliaproductos}
                  listatipoproductos={this.state.listatipoproductos}
                  listaunidadesmedida={this.state.listaunidadesmedida}
                  listanuevosproductos={this.state.listanuevosproductos}

                  listaareas={this.state.listaareas}
                  listadestinos={this.state.listadestinos}

                  listazonas={this.state.listazonas}
                  listaranchos={this.state.listaranchos}
                  listaalamcenproductosgenerales={this.state.listaalamcenproductosgenerales}
                  listaalmacenequipos={this.state.listaalmacenequipos}

                  listavehiculos={this.state.listavehiculos}
                />
              </div>
            </div>

          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}



        {/* ////////////////////   Fin Sección Tabla  Resumen ////////////////////////////////////// */}
        {
          (this.state.inforequisicion.idestatusrequisicion == 1 ||
            this.state.inforequisicion.idestatusrequisicion == 2 ||
            this.state.inforequisicion.idestatusrequisicion == 4 ||
            this.state.inforequisicion.idestatusrequisicion == null) ?
            (
              <>
                <br />

                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedorModal spacePaddingBottom" >
                  <Button className="btnCancelModal" onClick={this.cancelarFormulario}>Cancelar</Button>
                  <Button className="btnAceptarModal"
                    onClick={this.validarDatos}
                    disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>

            ) : null

        }




        {/* ////////////////// sección para aprobar o rechazar requisición general//////////////////// */}
        {
        (this.state.inforequisicion.idestatusrequisicion == 2 && this.state.inforequisicion.idestatusrequisicion != null) ? 
        //this.state.estatuseditable &&
          <div className="divBtnContenedorModal < divAprobar spacePaddingBottomAA" >
            <Button className="btnCancelModal" onClick={this.verModalFormularioAccionEstatus.bind(this, { id: 1 })} //onClick={this.cancelarFormulario}
              sttyle={{ minWidth: '220px' }}>Rechazar requisición</Button>
            <Button className="btnAceptarModal" onClick={this.verModalFormularioAccionEstatusContrasena.bind(this, { id: 2, accion: 1 })}
                //onClick={this.validarDatos}
                /*disabled={!this.state.confirmarenvio}*/ >Aprobar requisición</Button>
          </div>
          :null
        }




      </>
    )
  }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" style={{ color: '#0061FE' }} />
  </button>
));

function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  } else if (bandera == "catalogoreq") {
    return (<Redirect from='/' to={"/catalogorequisicionesgenerales"} />)

  }
}
